import { LimitType, UserRole } from 'legoland-sdk/dist/experimental';
import { SharedLimitSchema } from 'products/types/Limit';

export const sharedLimitSchemas: SharedLimitSchema[] = [
  {
    customizable: true,
    description: '',
    displayOrder: 4000,
    label: 'Number of kilometers to consume',
    name: 'totalRoadNetwork',
    required: true,
    requiredRole: UserRole.Distributor,
    type: LimitType.Km,
    unitLabel: 'km',
    visible: true,
  },
  {
    customizable: true,
    description:
      'Customer can only create up to X reports in O/D with this limit',
    displayOrder: 5000,
    label: 'Number of reports to create',
    name: 'agreementJobs',
    requiredRole: UserRole.Distributor,
    type: LimitType.Count,
    unitLabel: 'report',
    visible: true,
  },
];
