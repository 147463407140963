import { ContractPartial } from './contractTypes';

export function getContractDisplayName(contract: ContractPartial) {
  const parts: string[] = [];
  if (contract.contractId) {
    parts.push(contract.contractId);
  }
  if (contract.name) {
    parts.push(contract.name);
  }
  return parts.length > 0 ? parts.join(' | ') : 'Unnamed contract';
}
