import { Contract } from 'app/modules/contracts/contractTypes';
import {
  AccessType,
  EmailNotificationsSettings,
  isUserProductFallback,
  UserProduct,
  UserRole,
} from 'legoland-sdk/dist/experimental';
import { SortingRule } from 'react-table';
import { SettingsFormValues } from 'users/details/SettingsForm/SettingsForm';
import {
  productFormFromProduct,
  ProductUpdateDto,
  resellProductFromProduct,
  ResellProductUpdateDto,
} from './Product';
import { UserStatus } from './UserStatus';

export interface UserNote {
  id: number;
  owner: string;
  writer: string;
  content: string;
  createdAt: Date;
}

export enum AccessHistoryStatus {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
  Terminated = 'TERMINATED',
}

export enum UserType {
  Portal = 'PORTAL',
  ApiOnly = 'API_ONLY',
}

export enum Pricing {
  OldNetworkModel = 'OLD_NETWORK_MODEL',
  NetworkPackages = 'NETWORK_PACKAGES',
}

export interface UserDto {
  blockOldAccessProcess: boolean;
  contracts: Contract[];
  activated: boolean;
  archived: boolean;
  createdAt: string;
  lastActivity: string;
  customerType: string;
  email: string;
  emailNotifications?: Record<string, EmailNotificationsSettings>;
  locked: boolean;
  organization: UserOrganizationDto;
  pricing: Pricing;
  products: UserProduct[];
  role: UserRole;
  settings: UserSettings;
  type: UserType;
  status?: UserStatus | undefined;
}

export interface UserSettings {
  units: 'KM' | 'MI';
  clockFormat: '12' | '24';
  weekStart: 'MONDAY' | 'SUNDAY';
  dateFormat: 'DDMMYYYY' | 'MMDDYYYY';
}

export type User = UserDto;

export type NewUser = Pick<
  UserDto,
  'email' | 'role' | 'organization' | 'settings'
>;

export interface AccessHistoryItem {
  endDate: string;
  startDate: string;
  status: AccessHistoryStatus;
  type: AccessType;
}

export interface UserOrganizationDto {
  id: number;
  name?: string;
}

export interface UserUpdateDto {
  email: string;
  emailNotifications?:
    | Record<string, boolean>
    | Record<string, EmailNotificationsSettings>;
  role: UserRole;
  organization: UserOrganizationDto;
  settings: SettingsFormValues;
  pricing: Pricing;
  products?: ProductUpdateDto[];
  grantedProducts?: ResellProductUpdateDto[];
  accountNotLocked?: boolean;
  isArchived?: boolean;
  type: UserType;
}

export interface RegistrationFormValues {
  company: string;
  country: string;
  email: string;
  firstName: string;
  industry: string;
  lastName: string;
  products: string[];
  termsAgreement: boolean;
}

export enum CustomerType {
  RegularCustomer = 'REGULAR CUSTOMER',
  Distributor = 'DISTRIBUTOR',
  DistributorsCustomer = "DISTRIBUTOR'S CUSTOMER",
  ApiOnly = 'API ONLY',
}

export type UsersQuerySortBy = SortingRule<User>[];

export const userUpdateFromUserDto = (user: UserDto): UserUpdateDto => ({
  type: user.type,
  email: user.email,
  emailNotifications: user.emailNotifications,
  role: user.role,
  organization: user.organization,
  settings: user.settings,
  pricing: user.pricing,
  products: user.products
    .filter(
      (p) =>
        p.access.status === 'GRANTED' ||
        p.access.status === 'PENDING' ||
        p.access.status === 'EXPIRED',
    )
    .map(productFormFromProduct),
  grantedProducts: user.products
    .filter((p) => !isUserProductFallback(p) && p.resell.status === 'ALLOWED')
    .map(resellProductFromProduct),
});
