import { format, parse } from 'date-fns';
import { useMemo } from 'react';

/**
 * Formats a date string to a human-readable format. The date string should be
 * in the format 'yyyy-MM-dd'. For example, '2022-01-01' will be formatted to '1
 * January 2022'. The result is memoized. Used for communication headers.
 *
 * @param date The date string to format.
 * @returns The formatted date string.
 */
export function useCommunicationDate(date: string) {
  return useMemo(
    () => format(parse(date, 'yyyy-MM-dd', new Date()), 'd MMMM yyyy'),
    [date],
  );
}
