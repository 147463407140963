import styled from 'styled-components';
import { Heading, propStyling, Text, tombac } from 'tombac';
const StyledRegistration = {
  Heading: styled(Heading).withConfig({
    displayName: "StyledRegistrationUi__Heading"
  })(["font-weight:", ";font-size:", ";text-align:center;"], _ref => {
    let {
      level
    } = _ref;
    return level === 1 ? 700 : 500;
  }, _ref2 => {
    let {
      level
    } = _ref2;
    return level === 1 ? '32px' : '20px';
  }),
  Page: styled.div.withConfig({
    displayName: "StyledRegistrationUi__Page"
  })(["background:", ";overflow:hidden;"], tombac.color('neutral', 200)),
  Content: styled.div.withConfig({
    displayName: "StyledRegistrationUi__Content"
  })(["margin:", " auto;width:", ";@media (max-width:", "){padding:", ";width:auto;}", ";"], tombac.space(7), tombac.unit(976), tombac.unit(1023), tombac.space(0, 3), propStyling),
  TermsLabel: styled.label.withConfig({
    displayName: "StyledRegistrationUi__TermsLabel"
  })(["display:flex;margin-top:", ";padding-top:", ";border-top:1px solid ", ";"], tombac.space(2), tombac.space(2), tombac.color('neutral', 400)),
  TermsLabelCopy: styled(Text).withConfig({
    displayName: "StyledRegistrationUi__TermsLabelCopy"
  })(["margin-top:", ";margin-left:", ";margin-bottom:", ";"], tombac.space(2), tombac.space(2), tombac.space(2))
};
export default StyledRegistration;