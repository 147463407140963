import { AppApiPartProps } from 'app/api/app-api/createAppApiClient';
import {
  OrganizationDto,
  OrganizationPartial,
  RefLinkCreateDto,
  RefLinkDto,
  ValidationResponse,
} from 'app/types/Organization';
import { BASE_URL } from 'core/apiClient';

export function organizationsApi({
  baseUrl,
  get,
  patchJson,
  postJson,
}: AppApiPartProps) {
  return {
    addOrganization({ organization }: { organization: OrganizationPartial }) {
      return postJson<OrganizationDto>(
        `${baseUrl}/organizations`,
        organization,
      );
    },

    getOrganizationList() {
      return get<OrganizationDto[]>(`${baseUrl}/organizations`);
    },

    getOrganizationDetails({ organizationId }: { organizationId: number }) {
      return get<OrganizationDto[]>(
        `${baseUrl}/organizations/${organizationId}`,
      );
    },

    updateOrganization({
      organizationId,
      changes,
    }: {
      organizationId: number;
      changes: OrganizationPartial;
    }) {
      return patchJson(`${baseUrl}/organizations/${organizationId}`, changes);
    },

    sendTransferEmail(refLinkCreateDto: RefLinkCreateDto) {
      return postJson<RefLinkDto>(
        `${baseUrl}/organizations/ref-links`,
        refLinkCreateDto,
      );
    },
  };
}

export async function validateTransfer(
  requestedUser: string,
): Promise<ValidationResponse<null>> {
  return await fetch(
    `${BASE_URL}/organizations/ref-links/users/${requestedUser}`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application-json',
      },
    },
  ).then((response) => {
    if (!response.ok) {
      return { error: true, data: null };
    }
    return { error: false, data: null };
  });
}

export async function transferUser(
  linkId: string,
): Promise<ValidationResponse<null>> {
  return await fetch(`${BASE_URL}/organizations/ref-links/${linkId}`, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'Content-Type': 'application-json',
    },
  }).then((response) => {
    if (!response.ok) {
      return { error: true, data: null };
    }
    return { error: false, data: null };
  });
}
