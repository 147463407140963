import { createAsyncHook } from 'app/hooks/useAsync';
import { UserType } from 'app/types/User';
import { AccessType, UserRole } from 'legoland-sdk/dist/experimental';
import { useEffect, useRef, useState } from 'react';
import defaultApiClient from '../modules/core/apiClient';

export interface HistoricalUserProductAccess {
  product: string;
  apiKey: string;
  accessType: AccessType;
  expiryDate: Date;
  accessRevoked: boolean;
}

export interface HistoricalUserProductResell {
  product: string;
  expiryDate: Date;
}

export interface HistoricalUser {
  email: string;
  role: UserRole;
  type: UserType;
  organization: string;
  activated: boolean;
  archived: boolean;
  locked: boolean;
  settings: any;
  sapId: string;
  access: { [id: string]: HistoricalUserProductAccess };
  resell: { [id: string]: HistoricalUserProductResell };
}

export interface HistoricalChange {
  rev: number;
  revType: 'create' | 'update';
  timestamp: Date;
  author: string;
  user: HistoricalUser;
  prevUser?: HistoricalUser;
}

export interface HistoryPageData {
  content: HistoricalChange[];
  empty: boolean;
  first: boolean;
  last: false;
  number: number;
  numberOfElements: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

const toMap = <T>(list: T[], getKey: (item: T) => string) => {
  const map: { [key: string]: T } = {};
  list.forEach((x) => (map[getKey(x)] = x));
  return map;
};

const processHistoryJson = (json: HistoricalChange[]) =>
  json.map((item) => {
    item.user.access = toMap(item.user.access as any, (x) => x.product);
    item.user.resell = toMap(item.user.resell as any, (x) => x.product);
    if (item.prevUser) {
      item.prevUser.access = toMap(
        item.prevUser.access as any,
        (x) => x.product,
      );
      item.prevUser.resell = toMap(
        item.prevUser.resell as any,
        (x) => x.product,
      );
    }
    return item;
  });

export interface HistoryOptions {
  apiKey?: string;
  author?: string;
  debounceMs?: number;
  email?: string;
  page?: number;
  size?: number;
}

export const getHistory = async ({
  apiKey,
  author,
  email,
  page = 0,
  size = 10,
}: HistoryOptions = {}) => {
  const query = { apiKey, author, email, page, size };

  const queryString = Object.keys(query)
    .map((key) =>
      query[key] !== undefined
        ? `${key}=${encodeURIComponent(query[key])}`
        : null,
    )
    .filter(Boolean)
    .join('&');

  const url = `/history${queryString ? `?${queryString}` : ''}`;
  const response = await defaultApiClient.request(url);
  const json = (await response.json()) as HistoryPageData;

  json.content = processHistoryJson(json.content);

  return json;
};

export const useHistory = (options: HistoryOptions = {}) => {
  const [history, setHistory] = useState<HistoryPageData>(undefined);
  const timerRef = useRef<number | undefined>();

  const updateHistory = async () => {
    try {
      const response = await getHistory(options);
      setHistory(response);
    } catch (e) {
      setHistory(e);
    }
  };

  useEffect(() => {
    if (!options.debounceMs || !history) {
      updateHistory();
      return;
    }
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = window.setTimeout(() => {
      timerRef.current = undefined;
      updateHistory();
    }, options.debounceMs);
  }, Object.values(options));

  return history;
};

export const getUserHistory = async (email: string) => {
  const response = await defaultApiClient.request(
    `/history/${encodeURIComponent(email)}`,
  );
  const json = (await response.json()) as HistoricalChange[];
  return processHistoryJson(json);
};

export const useUserHistory = createAsyncHook(getUserHistory);
