type ProductId = 'ctm' | 'od' | 'ts';
type AppEnvValue = typeof process.env.APP_ENV;

type Feature = never; // 'FEATURE' | 'ANOTHER_FEATURE';
type FeatureToggles = Record<Feature, boolean>;

interface EvaluationConfig {
  isEvaluationAvailable: boolean;
  evaluationLengthInDays: number;
}

interface EnvironmentConfig {
  API_URL: string;
  AUTH_API_URL: string;
  PRODUCT_API_URL: Record<ProductId, string>;
  FEATURES: FeatureToggles;
  EVALUATION: Record<ProductId, EvaluationConfig>;
}

const evaluationConfig = {
  ctm: {
    isEvaluationAvailable: true,
    evaluationLengthInDays: 30,
  },
  ts: {
    isEvaluationAvailable: true,
    evaluationLengthInDays: 30,
  },
  od: {
    isEvaluationAvailable: true,
    evaluationLengthInDays: 30,
  },
  ja: {
    isEvaluationAvailable: true,
    evaluationLengthInDays: 30,
  },
};

const preprodConfig: EnvironmentConfig = {
  API_URL: 'https://preprod.api.move.tomtom.com',
  AUTH_API_URL: 'https://preprod.auth.move.tomtom.com',
  PRODUCT_API_URL: {
    ctm: 'http://warta-01:8990/routemonitoring/1',
    od: 'https://preprod.api-system.tomtom.com/origindestination/rest',
    ts: 'http://warta-01:8880/traffic/trafficstats',
  },
  FEATURES: {},
  EVALUATION: evaluationConfig,
};

const appConfig: Record<AppEnvValue, EnvironmentConfig> = {
  local: preprodConfig,
  preprod: preprodConfig,
  prod: {
    API_URL: 'https://api.move.tomtom.com',
    AUTH_API_URL: 'https://auth.move.tomtom.com',
    PRODUCT_API_URL: {
      ctm: 'https://api.tomtom.com/routemonitoring/1',
      od: 'https://api.tomtom.com/origindestination/rest',
      ts: 'https://api.tomtom.com/traffic/trafficstats',
    },
    FEATURES: {},
    EVALUATION: evaluationConfig,
  },
  test: {
    API_URL: 'https://test.api.move.tomtom.com',
    AUTH_API_URL: 'https://test.auth.move.tomtom.com',
    PRODUCT_API_URL: {
      ctm: 'https://api.tomtom.com/routemonitoring/1',
      od: 'https://api.tomtom.com/origindestination/rest',
      ts: 'https://api.tomtom.com/traffic/trafficstats',
    },
    FEATURES: {},
    EVALUATION: evaluationConfig,
  },
};

export default appConfig[process.env.APP_ENV];
