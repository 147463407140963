import defaultApiClient from 'core/apiClient';

const pad = (number) => ('0' + number).substr(-2);
export const formatDate = (date) =>
  date.getFullYear() +
  '-' +
  pad(date.getMonth() + 1) +
  '-' +
  pad(date.getDate());

const makeReportRequestUrl = (
  baseUrl,
  rangeStart,
  rangeEnd,
  excludeEmails,
  includeEmails,
  accessType,
  productId,
  sapId,
  organizationId,
) => {
  let filter = '';
  if (excludeEmails.length > 0) {
    filter += '&excludeEmailPattern=' + excludeEmails;
  }
  if (includeEmails.length > 0) {
    filter += '&includeEmailPattern=' + includeEmails;
  }
  if (accessType) {
    filter += '&accessType=' + accessType;
  }
  if (sapId) {
    filter += '&sapId=' + sapId;
  }
  if (organizationId) {
    filter += '&organizationId=' + organizationId;
  }

  return `${baseUrl}?from=${formatDate(rangeStart)}&to=${formatDate(
    rangeEnd,
  )}&productId=${productId}${filter}`;
};

const createClient = (apiClient) => ({
  get: async (
    productId,
    rangeStart,
    rangeEnd,
    excludeEmails,
    includeEmails,
    accessType,
  ) => {
    const url = makeReportRequestUrl(
      '/report/json',
      rangeStart,
      rangeEnd,
      excludeEmails,
      includeEmails,
      accessType,
      productId,
    );

    const parsed = await apiClient.request(url).then((r) => r.json());

    return { parsed, productId, rangeStart, rangeEnd };
  },

  getExcludedRecordsList: async (productId) => {
    const parsed = await apiClient
      .request(`/report/exclusions?productId=${productId}`)
      .then((r) => r.json());
    return parsed;
  },
  addNewExcludedRecord: async (productId, recordId) => {
    return defaultApiClient
      .request('/report/excluded-records', {
        method: 'POST',
        body: JSON.stringify({ productId, recordId }),
      })
      .then((response) => {
        if (response.ok) {
          return Promise.resolve();
        }
        return Promise.reject();
      });
  },

  deleteExcludedRecord: async (productId, recordId) => {
    return defaultApiClient
      .request(`/report/excluded-records/${productId}/${recordId}`, {
        method: 'DELETE',
      })
      .then((response) => {
        if (response.ok) {
          return Promise.resolve();
        }
        return Promise.reject();
      });
  },
});

export default createClient(defaultApiClient);
