import styled from 'styled-components';
import { Button, tombac } from 'tombac';
export const OrganizationButton = styled(Button).attrs(() => ({
  size: 's',
  variant: 'flat'
})).withConfig({
  displayName: "OrganizationShared__OrganizationButton"
})(["background-color:transparent;margin:", ";text-decoration:", ";", ""], tombac.space(-1, 0), _ref => {
  let {
    disabled
  } = _ref;
  return disabled ? undefined : 'underline';
}, tombac.text());
export const OrganizationPopupForm = styled.form.withConfig({
  displayName: "OrganizationShared__OrganizationPopupForm"
})(["background-color:", ";border:", " solid ", ";box-shadow:", " rgba(0,0,0,0.1);padding:", ";position:absolute;right:0;z-index:1;"], tombac.color.white, tombac.unit(1), tombac.color('neutral', 400), tombac.unit(0, 0, 5, 0), tombac.space(0.5));