import { LimitValues } from 'legoland-sdk/dist/experimental';
import {
  EffectiveLimits,
  LimitDefaultsDto,
  LimitsDto,
} from 'products/types/Limit';
import { AppApiPartProps } from '../../../api/app-api/createAppApiClient';

export function limitsApi({ baseUrl, del, get, postJson }: AppApiPartProps) {
  return {
    async getAccessLimits({
      productId,
      username,
    }: {
      productId: string;
      username: string;
    }) {
      return await get<LimitValues>(
        `${baseUrl}/limits/access/${username}/${productId}`,
      );
    },

    async getEffectiveLimits({
      productId,
      username,
    }: {
      productId: string;
      username: string;
    }) {
      return await get<EffectiveLimits>(
        `${baseUrl}/limits/effective/${encodeURIComponent(
          username,
        )}/${productId}`,
      );
    },

    async getLimitDefaults() {
      return await get<LimitDefaultsDto>(`${baseUrl}/limits/defaults`);
    },

    async getSharedLimits({ username }: { username?: string }) {
      return await get<{ limits: LimitsDto }>(
        `${baseUrl}/limits/shared/${encodeURIComponent(username)}`,
      );
    },

    async removeSharedLimits({ username }: { username: string }) {
      return await del(
        `${baseUrl}/limits/shared/${encodeURIComponent(username)}`,
      );
    },

    async setLimits({
      limits,
      productId,
      username,
    }: {
      limits: LimitValues;
      productId: string;
      username: string;
    }) {
      return await postJson(
        `${baseUrl}/limits/access/${encodeURIComponent(username)}/${productId}`,
        limits,
      );
    },

    async setSharedLimits({
      limits,
      username,
    }: {
      limits: LimitsDto;
      username?: string;
    }) {
      return await postJson(
        `${baseUrl}/limits/shared/${encodeURIComponent(username)}`,
        { limits },
      );
    },
  };
}
