import {
  createApiQueryHook,
  useApiMutation,
} from 'app/api/app-api/apiHooksHelpers';
import { useQueryClient } from 'react-query';

export function useAddCommunicationMutation() {
  const queryClient = useQueryClient();
  return useApiMutation('addCommunication', {
    mutationOptions: {
      onSettled() {
        queryClient.invalidateQueries(['legoland', 'communications']);
      },
    },
  });
}

export const useCommunicationEmailRecipientsQuery = createApiQueryHook(
  ({ communicationItemId }) => [
    'legoland',
    'communications',
    communicationItemId,
    'emails',
    'recipients',
  ],
  'getCommunicationEmailRecipients',
);

export const useCommunicationItemQuery = createApiQueryHook(
  ({ communicationItemId }) => [
    'legoland',
    'communications',
    communicationItemId,
  ],
  'getCommunicationItem',
);

export const useCommunicationListQuery = createApiQueryHook(
  (query) => ['legoland', 'communications', query],
  'getCommunicationList',
);

export function useDeleteCommunicationMutation() {
  const queryClient = useQueryClient();
  return useApiMutation('deleteCommunication', {
    mutationOptions: {
      onSettled() {
        queryClient.invalidateQueries(['legoland', 'communications']);
      },
    },
  });
}

export function useSendCommunicationEmailMutation() {
  const queryClient = useQueryClient();
  return useApiMutation('sendCommunicationEmail', {
    mutationOptions: {
      onSettled() {
        queryClient.invalidateQueries(['legoland', 'communications']);
      },
    },
  });
}

export function useSendCommunicationTestEmailMutation() {
  return useApiMutation('sendCommunicationTestEmail');
}

export function useUnsubscribeCommunicationEmailMutation() {
  return useApiMutation('unsubscribeCommunicationEmail');
}

export function useUpdateCommunicationItemMutation() {
  const queryClient = useQueryClient();
  return useApiMutation('updateCommunicationItem', {
    mutationOptions: {
      onSettled() {
        queryClient.invalidateQueries(['legoland', 'communications']);
      },
    },
  });
}
