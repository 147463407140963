import { CollectionDto } from 'app/api/app-api/apiTypes';
import { AppApiPartProps } from 'app/api/app-api/createAppApiClient';
import { Page } from 'app/modules/contracts/list/Pagination';
import { CustomerType, UserDto, UsersQuerySortBy } from 'app/types/User';
import { UserStatus } from 'app/types/UserStatus';
import { AccessType, UserRole } from 'legoland-sdk/dist/experimental';
import queryString from 'query-string';

export interface UsersQueryFilters {
  roles?: UserRole[];
  products?: string[];
  statuses?: UserStatus[];
  customerTypes?: CustomerType[];
  productAccessTypes?: AccessType[];
  searchValues?: string;
}

export interface UsersQuery {
  page?: Page;
  sortBy?: UsersQuerySortBy;
  filters?: UsersQueryFilters;
}

export function usersApi({ baseUrl, get }: AppApiPartProps) {
  return {
    getUsersList(query: UsersQuery) {
      const { filters, sortBy, page } = query;
      const filterQuery = {
        ...filters,
        page: page?.index,
        size: page?.size,
        sort:
          sortBy && sortBy.length > 0
            ? `${sortBy[0].id},${sortBy[0].desc ? 'DESC' : 'ASC'}`
            : undefined,
      };

      const url = new URL(`${baseUrl}/users`);
      url.search = queryString.stringify(filterQuery);
      return get<CollectionDto<UserDto>>(url.toString());
    },
  };
}

export const createUsersLinkFromQuery = (
  query: UsersQuery,
  format?: string,
) => {
  const SEARCH_ATTRIBUTES = 'email,organization.name,products.apiKey,sapId';
  const {
    page,
    sortBy,
    filters: {
      roles,
      products,
      customerTypes,
      productAccessTypes,
      searchValues,
      statuses,
    },
  } = query;

  const search = searchValues ? encodeURIComponent(searchValues) : '';

  const direction =
    sortBy && sortBy.length > 0 && sortBy[0].desc ? 'DESC' : 'ASC';
  const sortParam =
    sortBy && sortBy.length > 0 ? `&sort=${sortBy[0].id},${direction}` : '';

  const searchParam =
    search && search !== ''
      ? `&searchAttributes=${SEARCH_ATTRIBUTES}&searchValues=${search}`
      : '';

  const productsFilter =
    products && products.length > 0 ? `&products=${products.join(',')}` : '';
  const rolesFilter =
    roles && roles.length > 0 ? `&roles=${roles.join(',')}` : '';
  const statusesFilter =
    statuses && statuses.length > 0 ? `&statuses=${statuses.join(',')}` : '';
  const customerTypesFilter =
    customerTypes && customerTypes.length > 0
      ? `&customerTypes=${customerTypes.join(',')}`
      : '';
  const accessTypesFilter =
    productAccessTypes && productAccessTypes.length > 0
      ? `&productAccessTypes=${productAccessTypes.join(',')}`
      : '';

  const formatParam = format === 'xls' ? '&format=xls' : '';

  return `/users?page=${page.index}&size=${page.size}${sortParam}${productsFilter}${rolesFilter}${statusesFilter}${customerTypesFilter}${accessTypesFilter}${searchParam}${formatParam}`;
};
