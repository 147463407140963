import Cookies from 'js-cookie';

const isBrowser = typeof window !== 'undefined';

const COOKIE_KEY = 'tt_settings';

const DEFAULT_COOKIE = {
  url: 'https://www.tomtom.com/en_gb/cookies/',
  locale: 'en_GB',
  version: '2.0',
  accepted: false,
  all: false,
  options: { 'site-improvement': false },
};

const DEFAULT_COOKIE_PARAMS = {
  domain: 'tomtom.com',
  expires: 365,
};

function getCookie() {
  const cookie = Cookies.getJSON(COOKIE_KEY) || DEFAULT_COOKIE;
  return cookie;
}

const cookieManager = {
  isAccepted() {
    if (!isBrowser) {
      return true;
    }

    return getCookie().all;
  },
  isDeclined() {
    if (!isBrowser) {
      return false;
    }

    return !getCookie().all;
  },
  isActedUpon() {
    if (!isBrowser) {
      return true;
    }
    return getCookie().accepted;
  },
  accept() {
    const cookie = getCookie();

    const updated = {
      ...cookie,
      accepted: true,
      all: true,
    };

    Cookies.set(COOKIE_KEY, updated, DEFAULT_COOKIE_PARAMS);
  },
  decline() {
    const cookie = getCookie();

    const updated = {
      ...cookie,
      accepted: true,
      all: false,
    };

    Cookies.set(COOKIE_KEY, updated, DEFAULT_COOKIE_PARAMS);
  },
};

export default cookieManager;
