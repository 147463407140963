import {
  createApiQueryHook,
  useApiMutation,
} from 'app/api/app-api/apiHooksHelpers';
import { useQueryClient } from 'react-query';

export function useAddOrganizationMutation() {
  const queryClient = useQueryClient();
  return useApiMutation('addOrganization', {
    mutationOptions: {
      onSettled() {
        queryClient.invalidateQueries(['legoland', 'organizations']);
      },
    },
  });
}

export const useOrganizationListQuery = createApiQueryHook(
  ['legoland', 'organizations'],
  'getOrganizationList',
);

export const useOrganizationQuery = createApiQueryHook(
  ({ organizationId }) => ['legoland', 'organizations', organizationId],
  'getOrganizationDetails',
);

export function useUpdateOrganizationMutation() {
  const queryClient = useQueryClient();
  return useApiMutation('updateOrganization', {
    mutationOptions: {
      onSettled() {
        queryClient.invalidateQueries(['legoland', 'organizations']);
      },
    },
  });
}

export function useSendTransferEmailMutation() {
  const queryClient = useQueryClient();
  return useApiMutation('sendTransferEmail', {
    mutationOptions: {
      onSettled() {
        queryClient.invalidateQueries(['legoland', 'organizations']);
      },
    },
  });
}
