import { ChangeEventHandler, useState } from 'react';

function useInput<T extends string | number>(defaultValue: T) {
  let defaultValueIsEmpty = false;
  if (typeof defaultValue === 'string' && defaultValue.trim().length === 0) {
    defaultValueIsEmpty = true;
  }
  if (defaultValue === undefined) {
    defaultValueIsEmpty = true;
  }

  const [pristine, setPristine] = useState(defaultValueIsEmpty ? true : false);
  const [value, setValue] = useState<T>(defaultValue);
  return [
    {
      value,
      onChange: (e) => {
        if (pristine) {
          setPristine(false);
        }
        setValue(e.target.value as T);
      },
    } as {
      value: T;
      onChange: ChangeEventHandler<HTMLInputElement>;
    },
    setValue,
    pristine,
  ] as const;
}

export default useInput;
