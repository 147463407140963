import { CollectionDto } from 'app/api/app-api/apiTypes';
import { AppApiPartProps } from 'app/api/app-api/createAppApiClient';
import queryString from 'query-string';
import {
  Contract,
  ContractLimits,
  ContractOverlaps,
  ContractPartial,
  ContractUser,
  ContractUserPartial,
  ContractsQuery,
} from './contractTypes';

export function contractsApi({
  baseUrl,
  get,
  patchJson,
  postJson,
  putJson,
}: AppApiPartProps) {
  return {
    addContract({ contract }: { contract: ContractPartial }) {
      return postJson(`${baseUrl}/contracts`, contract);
    },

    getContract({ contractId }: { contractId: number }) {
      return get<Contract>(`${baseUrl}/contracts/${contractId}`);
    },

    getContractByUri({ uri }: { uri: string }) {
      const url = new URL(uri, baseUrl);
      return get<Contract>(url.toString());
    },

    getContractLimits({ contractId }: { contractId: number }) {
      return get<ContractLimits>(`${baseUrl}/contracts/${contractId}/limits`);
    },

    getContractList(query: ContractsQuery) {
      const { filters, sortBy, page } = query;
      const filterQuery = {
        ...filters,
        page: page?.index,
        size: page?.size,
        sort:
          sortBy && sortBy.length > 0
            ? `${sortBy[0].id},${sortBy[0].desc ? 'DESC' : 'ASC'}`
            : undefined,
      };
      const url = new URL(`${baseUrl}/contracts`);
      url.search = queryString.stringify(filterQuery);
      return get<CollectionDto<Contract>>(url.toString());
    },

    getContractUsers({ contractId }: { contractId: number }) {
      return get<ContractUser[]>(`${baseUrl}/contracts/${contractId}/users`);
    },

    reassignContractUsers({
      assign,
      unassign,
    }: {
      assign: ContractPartial[];
      unassign: ContractPartial[];
    }) {
      return postJson(`${baseUrl}/contracts/users`, { assign, unassign });
    },

    resolveContractOverlaps({ contract }: { contract: ContractPartial }) {
      return postJson<ContractOverlaps[]>(
        `${baseUrl}/contracts/overlaps`,
        contract,
      );
    },

    updateContract({
      changes,
      contractId,
    }: {
      changes: ContractPartial;
      contractId: number;
    }) {
      return patchJson(`${baseUrl}/contracts/${contractId}`, changes);
    },

    setContractUsers({
      contractId,
      users,
    }: {
      contractId: number;
      users: ContractUserPartial[];
    }) {
      return putJson(`${baseUrl}/contracts/${contractId}/users`, users);
    },

    setContractProductLimits({
      contractId,
      productId,
      limits,
    }: {
      contractId: number;
      productId: string;
      limits: any;
    }) {
      return putJson(
        `${baseUrl}/contracts/${contractId}/limits/products/${productId}`,
        limits,
      );
    },

    setContractProductSharedLimits({
      contractId,
      limits,
    }: {
      contractId: number;
      limits: any;
    }) {
      return putJson(
        `${baseUrl}/contracts/${contractId}/limits/package`,
        limits,
      );
    },
  };
}

export const createReportsLinkFromContract = (
  id: number,
  startDate: string,
  endDate: string,
  productsToSend: string[],
  reportType: string,
) => {
  const products = `&products=${productsToSend.join(',')}`;

  return (
    `/contracts/report?id=${id}&startDate=${startDate}&endDate=${endDate}` +
    `${products}&reportType=${reportType}`
  );
};
