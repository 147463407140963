import { UserDto } from './User';

export enum UserStatus {
  Inactive = 'INACTIVE',
  Active = 'ACTIVE',
  Locked = 'LOCKED',
  Deleted = 'DELETED',
}

export const parseFromUser = (user: UserDto) => {
  if (user.archived === true) {
    return UserStatus.Deleted;
  }
  if (user.locked === true) {
    return UserStatus.Locked;
  }
  if (user.activated === false) {
    return UserStatus.Inactive;
  }
  return UserStatus.Active;
};
