import { utc } from 'moment';
import { CommunicationItem } from '../../communicationTypes';
import { CommunicationFormValues } from './communicationValidationSchema';

export function convertCommunicationFormValuesToItem(
  values: CommunicationFormValues,
) {
  return {
    ...values,
    date: utc(values.date).startOf('day').toISOString().slice(0, 10),
  } as CommunicationItem;
}
