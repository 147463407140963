import Styled from 'app/modules/registration/StyledRegistrationUi';
import { Link } from 'react-router-dom';
import { Box, Button } from 'tombac';
import { SetPasswordModes } from './LoginTypes';

export const UserSubmitted = ({ mode }) => (
  <>
    <Styled.Heading level={1} $mb="1sp">
      {mode === SetPasswordModes.Register && 'Your password has been set.'}
      {mode === SetPasswordModes.Recover &&
        ' Your password has been changed. You may now sign in!'}
    </Styled.Heading>
    <Styled.Heading as="p" level={2} $mb="4sp">
      You may sign in now and try out our Road Analytics product(s).
    </Styled.Heading>
    <Box $textAlign="center" className="LoginPopup__go-back">
      <Link to="/login">
        <Button className="LoginPopup__button" variant="primary">
          Log in to MOVE
        </Button>
      </Link>
    </Box>
  </>
);

export const SubscriptionActivated = ({ source }) => (
  <>
    <Styled.Heading level={1} $mb="1sp">
      Your {source.toLowerCase()} has been activated.
    </Styled.Heading>
    <Styled.Heading as="p" level={2} $mb="4sp">
      Please log in to use product
    </Styled.Heading>
    <Box className="LoginPopup__go-back">
      <Link to="/login">
        <Button className="LoginPopup__button" variant="primary">
          Log in to MOVE
        </Button>
      </Link>
    </Box>
  </>
);

export const LinkExpired = ({ errorMessage }) => (
  <div className="SetPasswordPopup LoginPopup__modal py-5">
    <div className="LoginPopup__form">
      <h1 className="LoginPopup__title">The link has expired</h1>
      <div className="LoginForm">
        <p className="form-feedback form-feedback--error LoginPopup__feedback">
          {errorMessage
            ? errorMessage
            : 'Unfortunately the link has expired. Please request another password change to receive a new one.'}
        </p>
      </div>
    </div>
  </div>
);

export const ActivateError = ({ error }) => (
  <div className="SetPasswordPopup LoginPopup__modal py-5">
    <div className="LoginPopup__form">
      <h1 style={{ textAlign: 'center' }} className="LoginPopup__title">
        We couldn&apos;t activate your subscription
      </h1>
      <div className="LoginForm">
        <p className="form-feedback--subscription-error LoginPopup__feedback">
          {error ? error : 'An error has occurred, please contact move support'}
        </p>
        <p className="form-feedback--contact">
          Please contact us at{' '}
          <a href="mailto:movesupport@tomtom.com">movesupport@tomtom.com</a> if
          you need help
        </p>
      </div>
    </div>
  </div>
);
