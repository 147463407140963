import UserApi from 'app/api/UserApi';
import { useEffect, useState } from 'react';

const useEmailAvailability = (email: string, emailIsValid: boolean) => {
  const [emailAvailable, setEmailAvailable] = useState<
    'idle' | 'checking' | 'no' | 'yes'
  >('idle');

  useEffect(() => {
    const check = async () => {
      if (email.trim().length === 0 || !emailIsValid) {
        setEmailAvailable('idle');
        return;
      }
      setEmailAvailable('checking');

      const available = await UserApi.isAvailable(email);
      setEmailAvailable(available ? 'yes' : 'no');
    };

    const handler = setTimeout(() => check(), 100);
    return () => clearTimeout(handler);
  }, [email, emailIsValid]);

  return emailAvailable;
};

export default useEmailAvailability;
