import defaultApiClient from 'core/apiClient';

function createClient(client) {
  return {
    resetPassword(email) {
      return client
        .request('/recoverpassword/recover', {
          method: 'POST',
          body: JSON.stringify({
            email,
          }),
        })
        .then((response) => {
          if (response.status !== 200) {
            return Promise.reject();
          }

          return response.json();
        });
    },

    setPassword(password, confirmPassword, query) {
      return client
        .request(`/recoverpassword/setpassword${query}`, {
          method: 'POST',
          body: JSON.stringify({
            newPassword: password,
            newPasswordConfirmation: confirmPassword,
          }),
        })
        .then((response) => {
          if (response.status !== 200) {
            return Promise.reject();
          }
          return Promise.resolve();
        });
    },

    verifyToken(query) {
      return client
        .request(`/recoverpassword/verify${query}`, {
          method: 'GET',
        })
        .then((response) => {
          if (response.status !== 200) {
            return Promise.reject();
          }
          return Promise.resolve();
        });
    },
  };
}

export default createClient(defaultApiClient);
