import ForgotPasswordPopup from 'auth/Login/ForgotPasswordPopup';
import LoginPopup from 'auth/Login/LoginPopup';
import SetPasswordPopup from 'auth/Login/SetPasswordPopup';
import { SentryRoute } from 'core/SentryRoute';
import { Redirect, Switch } from 'react-router-dom';
import { SetPasswordModes } from './LoginTypes';

import './Login.css';

const LoginPage = () => {
  return (
    <div className="LoginPage">
      <Switch>
        <SentryRoute exact path="/login" component={LoginPopup} />
        <SentryRoute
          exact
          path="/login/set-password"
          render={(props) => (
            <SetPasswordPopup {...props} mode={SetPasswordModes.Recover} />
          )}
        />
        <SentryRoute
          exact
          path="/login/activate"
          render={(props) => (
            <SetPasswordPopup {...props} mode={SetPasswordModes.Register} />
          )}
        />
        <SentryRoute
          exact
          path="/login/forgot-password"
          component={ForgotPasswordPopup}
        />
        <SentryRoute render={() => <Redirect to="/not-found" />} />
      </Switch>
    </div>
  );
};

export default LoginPage;
