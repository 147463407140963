import './DashboardEmptyState.css';

import { Component } from 'react';

import { FormattedMessage } from 'react-intl';

class DashboardEmptyState extends Component {
  render() {
    return (
      <div className="DashboardEmptyState">
        <div>
          <svg
            className="DashboardEmptyState-cars"
            viewBox="20 22 80 75"
            width="100px"
            height="95px"
          >
            <g fill="#aaa">
              <g>
                <path
                  d="M83,63.3l-2.1-2.1c-0.6-0.6-1.4-1.9-1.7-2.7l-4-11.9c-1.1-3.4-5-6.2-8.7-6.2H40.3c-3.6,0-7.5,2.8-8.7,6.2l-4,11.9
              c-0.3,0.9-1,2.1-1.7,2.7c-0.6,0.6-1.3,1.3-2.1,2.1c-1.8,1.8-3.3,6.2-3.3,9.9v16.4v3.3c0,1.8,1.5,3.3,3.3,3.3h6.6
              c1.8,0,3.3-1.5,3.3-3.3v-3.3h39.5v3.3c0,1.8,1.5,3.3,3.3,3.3H83c1.8,0,3.3-1.5,3.3-3.3V73.1C86.3,69.5,84.8,65.1,83,63.3
               M28.8,79.7c-2.7,0-4.9-2.2-4.9-4.9c0-2.7,2.2-4.9,4.9-4.9c2.7,0,4.9,2.2,4.9,4.9C33.7,77.5,31.5,79.7,28.8,79.7 M64.9,83h-23
              c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6h23c0.9,0,1.6,0.7,1.6,1.6C66.6,82.3,65.8,83,64.9,83 M32.2,60
              c-0.9,0-1.4-0.7-1.1-1.6l3.6-10.9c0.7-2.1,3.3-4,5.5-4h26.3c2.2,0,4.8,1.9,5.5,4l3.6,10.9c0.3,0.9-0.2,1.6-1.1,1.6H32.2z
               M78.1,79.7c-2.7,0-4.9-2.2-4.9-4.9c0-2.7,2.2-4.9,4.9-4.9c2.7,0,4.9,2.2,4.9,4.9C83,77.5,80.8,79.7,78.1,79.7"
                />
                <path
                  d="M91.4,28.4c-1-2.5-4-4.6-6.8-4.6H64.9c-2.7,0-5.8,2.1-6.8,4.6L54.7,37h3.5l2.9-7.4c0.5-1.3,2.3-2.5,3.7-2.5h19.7
              c1.4,0,3.2,1.2,3.7,2.5l4,9.9c0.2,0.4-0.1,0.8-0.5,0.8h-17c1.6,1.4,2.9,3.2,3.6,5.2l4,12c0.1,0.4,0.5,1,0.8,1.4l1.2,1.2h5.2v3.3
              c0,1.8,1.5,3.3,3.3,3.3h3.3c1.8,0,3.3-1.5,3.3-3.3V46.8c0-1.8-0.7-4-1.6-4.9l-1.6-1.6L91.4,28.4z M92.9,53.4
              c-1.8,0-3.3-1.5-3.3-3.3s1.5-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3S94.7,53.4,92.9,53.4"
                />
              </g>
              <path d="M101.4,34" />
            </g>
          </svg>
        </div>
        <span className="DashboardEmptyState-copy">
          <FormattedMessage id="dashboard.empty.first-line" />
          <br />
          <FormattedMessage id="dashboard.empty.second-line" />
        </span>
      </div>
    );
  }
}

export default DashboardEmptyState;
