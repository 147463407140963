import { useRef, useState } from 'react';
import useCallableEffect from './useCallableEffect';
import useIsMounted from './useIsMounted';

export interface AsyncState<T> {
  error: any;
  status: 'ready' | 'pending' | 'resolved' | 'rejected';
  value: T;
}

type PromiseType<T = Promise<any>> = T extends Promise<infer P> ? P : never;

export function createAsyncHook<T extends (...args: any) => Promise<any>>(
  asyncFunc: T,
) {
  return (...args: Parameters<T>) => useAsync(asyncFunc, args);
}

type AsyncStateArrayTypes<T extends AsyncState<any>[]> = {
  [K in keyof T]: T[K] extends AsyncState<infer U> ? U : never;
};

export function mergeAsyncStates<T extends AsyncState<any>[]>(...states: T) {
  type ValueTypes = AsyncStateArrayTypes<T>;

  const combined: AsyncState<ValueTypes> = {
    error: null,
    status: null,
    value: states.map((state) => state.value) as ValueTypes,
  };

  for (const state of states) {
    if (state.error && !combined.error) combined.error = state.error;
    switch (state.status) {
      case 'rejected':
        combined.status = 'rejected';
        break;
      case 'pending':
        if (combined.status !== 'rejected') combined.status = 'pending';
        break;
      case 'resolved':
        if (combined.status === null) combined.status = 'resolved';
        break;
      case 'ready':
        if (combined.status === null) combined.status = 'ready';
        break;
      default:
    }
  }

  return combined;
}

export default function useAsync<
  T extends PromiseType<ReturnType<AsyncFunc>>,
  AsyncFunc extends (...args: any[]) => Promise<any>,
>(asyncFunc: AsyncFunc, args: Parameters<AsyncFunc>) {
  const mounted = useIsMounted();

  const [state, setState] = useState<AsyncState<T>>({
    error: null,
    status: 'ready',
    value: null,
  });

  const retry = useCallableEffect(() => {
    let effectActive = true;
    setState({ ...state, status: 'pending' });
    const isActive = () => mounted.current && effectActive;
    asyncFunc(...args)
      .then(
        (value) =>
          isActive() && setState({ error: null, status: 'resolved', value }),
      )
      .catch(
        (reason) =>
          isActive() &&
          setState({ ...state, error: reason, status: 'rejected' }),
      );
    return () => {
      effectActive = false;
    };
  }, [asyncFunc, ...args]);

  const actions = useRef({ retry });

  return [state.value, state, actions.current] as const;
}
