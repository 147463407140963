const tokens = {
  lessThanXSeconds: '< {{count}} sec.',
  xSeconds: '{{count}} sec.',
  halfAMinute: '½ min.',
  lessThanXMinutes: '< {{count}} min.',
  xMinutes: '{{count}} min.',
  aboutXHours: {
    one: '1 hr.',
    other: '{{count}} hrs.'
  },
  xHours: {
    one: '1 hr.',
    other: '{{count}} hrs.'
  },
  xDays: '{{count}} d.',
  aboutXWeeks: {
    one: '1 wk.',
    other: '{{count}} wks.'
  },
  xWeeks: {
    one: '1 wk.',
    other: '{{count}} wks.'
  },
  aboutXMonths: {
    one: '1 mo.',
    other: '{{count}} mos.'
  },
  xMonths: {
    one: '1 mo.',
    other: '{{count}} mos.'
  },
  aboutXYears: '{{count}} yr.',
  xYears: '{{count}} yr.',
  overXYears: '> {{count}} yr.',
  almostXYears: '< {{count}} yr.'
};
export const condencedDateLocale = {
  formatDistance(token, count, options) {
    options = options || {};
    let result;
    if (typeof tokens[token] === 'string') {
      result = tokens[token].replace('{{count}}', count);
    } else if (count === 1) {
      result = tokens[token].one;
    } else {
      result = tokens[token].other.replace('{{count}}', count);
    }
    if (options.addSuffix) {
      if (options.comparison > 0) {
        return 'in ' + result;
      } else {
        return result + ' ago';
      }
    }
    return result;
  }
};