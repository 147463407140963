import { FormikContext, useField } from 'formik';
import { useContext } from 'react';

/**
 * Use field if Formik's context is available, otherwise return an empty array.
 *
 * @param name Name of the field
 * @returns Field, meta and helpers if Formik's context is available, otherwise an empty array
 */
export function useFieldIfAvailable(name: string) {
  return useContext(FormikContext) // If within Formik's context use it instead of props
    ? // eslint-disable-next-line react-hooks/rules-of-hooks -- It is safe to disable this rule, because the context availability cannot change during the component's lifecycle.
      useField(name)
    : [];
}
