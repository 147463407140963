import { useApiErrorToast } from 'app/api/app-api/apiHooksHelpers';
import { useToasts } from 'tombac';
import { useAddCommunicationMutation, useUpdateCommunicationItemMutation } from "../../communicationsApiHooks";
import { convertCommunicationFormValuesToItem } from "./convertCommunicationFormValuesToItem";
export function useCommunicationSubmitHandler(_ref) {
  let {
    communicationItemId,
    onSuccess
  } = _ref;
  const addApiErrorToast = useApiErrorToast();
  const {
    addToast
  } = useToasts();
  const {
    isLoading: isAdding,
    mutateAsync: addCommunication
  } = useAddCommunicationMutation();
  const {
    isLoading: isUpdating,
    mutateAsync: updateCommunication
  } = useUpdateCommunicationItemMutation();
  async function handleReleaseNotesFormSubmit(values, _ref2) {
    let {
      resetForm
    } = _ref2;
    const communicationItem = convertCommunicationFormValuesToItem(values);
    try {
      if (communicationItemId) {
        await updateCommunication({
          changes: communicationItem,
          communicationItemId
        });
      } else {
        await addCommunication({
          communicationItem
        });
      }
      resetForm();
      addToast(`Release notes ${communicationItemId ? 'saved' : 'added'} successfully`, 'success');
      onSuccess();
    } catch (error) {
      addApiErrorToast(error);
    }
  }
  return {
    handleSubmit: handleReleaseNotesFormSubmit,
    isSubmitting: isAdding || isUpdating
  };
}