import { useProductsQuery } from 'legoland-sdk/dist/experimental';
import { useMemo } from 'react';

export type CommunicationProduct = {
  id: string;
  displayName: string;
};

export function useCommunicationProducts() {
  const query = useProductsQuery();

  const products = useMemo(
    () =>
      query.data
        ? [
            { id: 'll', displayName: 'Move' } as CommunicationProduct,
            ...query.data.products,
          ].sort((a, b) => a.displayName.localeCompare(b.displayName))
        : [],
    [query.data],
  );

  return { products, query };
}
