import { useMemo } from 'react';
import { UseQueryResult } from 'react-query';

export function useCombinedQueryStatus(...queries: UseQueryResult[]) {
  return useMemo(
    () => ({
      error: queries.map((q) => q.error),
      isError: queries.some((q) => q.isError),
      isLoading: queries.some((q) => q.isLoading),
      isSuccess: queries.every((q) => q.isSuccess),
    }),
    queries,
  );
}
