import defaultApiClient from 'core/apiClient';

function createClient(client) {
  return {
    setPassword(password, confirmPassword, query) {
      return client
        .request(`/registrations/passwords${query}`, {
          method: 'POST',
          body: JSON.stringify({
            newPassword: password,
            newPasswordConfirmation: confirmPassword,
          }),
        })
        .then((response) => {
          if (response.status >= 400) {
            return Promise.reject(response?.message ?? response);
          }
          return Promise.resolve();
        });
    },

    /*
    verifyToken(query) {
      return client
        .request(`/registrations${query}`, {
          method: 'GET',
        })
        .then((response) => {
          if (response.status >= 400) {
            return Promise.reject(response?.message ?? response);
          }

          return Promise.resolve(response);
          // return response.json().then((res) => console.log(res));
        });
    },

    */

    async verifyToken(query) {
      const response = await client.request(`/registrations${query}`, {
        method: 'GET',
      });
      const data = await response.json();
      return data;
    },

    async submitUserData(query) {
      const response = await client.request(`/registrations${query}`, {
        method: 'POST',
      });
      if (!response.ok) {
        const data = await response.json();
        return data;
      }
    },
  };
}

export default createClient(defaultApiClient);
