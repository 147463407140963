import {
  ClientQueryHook,
  ClientQueryHookOptionless,
  ClientReturnType,
  createMutationResponseHandler,
  getQueryResult,
} from 'app/api/app-api/apiHooksHelpers';
import { requireRole } from 'core/authHelpers';
import {
  LimitValues,
  Me,
  UserRole,
  useLegolandApiClient,
} from 'legoland-sdk/dist/experimental';
import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { useUserDetails } from 'users/UserDetailsProvider';
import { useAppApiClient } from '../../../api/app-api/AppApiClientContext';

export const useAccessLimitsQuery: ClientQueryHook<
  'getAccessLimits',
  'limits'
> = (options, queryOptions) => {
  const apiClient = useAppApiClient();
  return useQuery(
    ['legoland', 'limits', options.username, options.productId, 'access'],
    async () => {
      const response = await apiClient.getAccessLimits(options);
      const limits = await getQueryResult(response);
      return { limits, response };
    },
    { staleTime: 60000, ...queryOptions },
  );
};

export const useEffectiveLimitsQuery: ClientQueryHook<
  'getEffectiveLimits',
  'result'
> = (options, queryOptions?) => {
  const apiClient = useAppApiClient();
  return useQuery(
    ['legoland', 'limits', options.username, options.productId, 'effective'],
    async () => {
      const response = await apiClient.getEffectiveLimits(options);
      const result = await getQueryResult(response);
      return { response, result };
    },
    { staleTime: 60000, ...queryOptions },
  );
};

export const useLimitDefaultsQuery: ClientQueryHookOptionless<
  'getLimitDefaults',
  'defaults'
> = (queryOptions?) => {
  const apiClient = useAppApiClient();
  return useQuery(
    ['legoland', 'limits', 'defaults'],
    async () => {
      const response = await apiClient.getLimitDefaults();
      const defaults = await getQueryResult(response);
      return { defaults, response };
    },
    { staleTime: 60000, ...queryOptions },
  );
};

export const useRemoveUserLimitsMutation = () => {
  const legolandApiClient = useLegolandApiClient();
  const { mutateAsync: saveLimits } = useLimitsMutation();
  return useMutation(
    async ({
      me,
      productId,
      username,
    }: {
      me: Me;
      productId: string;
      username: string;
    }) => {
      const response = await legolandApiClient.getLimitSchemas({ productId });
      const schemas = await getQueryResult(response);
      if (!schemas) {
        throw new Error('Could not fetch schemas');
      }
      const limits: LimitValues = {};
      schemas
        .filter(
          (schema) =>
            schema.customizable &&
            (me.role === UserRole.Admin ||
              requireRole(schema.requiredRole, me.role)),
        )
        .forEach((schema) => (limits[schema.name] = null));
      return await saveLimits({ limits, productId, username });
    },
  );
};

export const useRemoveSharedLimitsMutation = () => {
  const apiClient = useAppApiClient();
  const queryClient = useQueryClient();
  return useMutation(
    createMutationResponseHandler(apiClient.removeSharedLimits),
    {
      onSettled(data, error, { username }) {
        queryClient.invalidateQueries(['legoland', 'limits', username]);
      },
    },
  );
};

export const useLimitsMutation = () => {
  const apiClient = useAppApiClient();
  const queryClient = useQueryClient();
  return useMutation(createMutationResponseHandler(apiClient.setLimits), {
    onSettled(data, error, { productId, username }) {
      queryClient.invalidateQueries([
        'legoland',
        'limits',
        username,
        productId,
      ]);
      queryClient.resetQueries([
        'legoland',
        'limits',
        username,
        productId,
        'access',
      ]);
    },
  });
};

export const useSharedLimitsMutation = () => {
  const apiClient = useAppApiClient();
  const queryClient = useQueryClient();
  return useMutation(createMutationResponseHandler(apiClient.setSharedLimits), {
    onSettled(data, error, { username }) {
      queryClient.invalidateQueries(['legoland', 'limits', username]);
    },
  });
};

export const useSharedLimitsQuery = (
  { username }: { username?: string } = {},
  queryOptions?: UseQueryOptions<{
    limits: LimitValues;
    response: ClientReturnType<'getSharedLimits'>;
  }>,
) => {
  const apiClient = useAppApiClient();
  username = username ?? useUserDetails()?.email;
  return useQuery(
    ['legoland', 'limits', username, 'values'],
    async () => {
      const response = await apiClient.getSharedLimits({ username });
      const limits = (await getQueryResult(response))?.limits ?? null;
      return { response, limits };
    },
    { staleTime: 600000, ...queryOptions },
  );
};
