import { Organization } from 'app/types/Organization';
import { UserDto } from 'app/types/User';
import { SortingRule } from 'react-table';
import { Page } from './list/Pagination';

export interface Contract {
  access: ContractAccess;
  accessType: ContractAccessType;
  contractId?: string;
  id: number;
  name?: string;
  organization: Organization;
  products: ContractProduct[];
  type: ContractType;
  users: ContractUser[];
  startDate: string;
  endDate: string;
  termDays: number;
  remainingDays: number;
  notes?: string;
  status: ContractStatus;
  package: ContractPackage;
}

export enum ContractAccess {
  ApiOnly = 'API_ONLY',
  Portal = 'PORTAL',
}

export enum ContractStatus {
  Upcoming = 'UPCOMING',
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
}

export enum ContractAccessType {
  Custom = 'CUSTOM',
  Demo = 'DEMO',
  Evaluation = 'EVALUATION',
  Internal = 'INTERNAL',
  Paid = 'PAID',
  Distribution = 'DISTRIBUTION',
}

export enum ContractReportAccessType {
  Custom = 'CUSTOM',
  Demo = 'DEMO',
  Evaluation = 'EVALUATION',
  Internal = 'INTERNAL',
  Paying = 'PAYING',
  Distribution = 'DISTRIBUTION',
}

export type ContractPartial = Omit<
  Partial<Contract>,
  'organization' | 'users'
> & {
  organization?: { id: number | null; sapId?: string };
  users?: ContractUserPartial[];
};

export interface ContractsQueryFilters {
  searchValue?: string;
  statuses?: ContractStatus[];
  accessTypes?: ContractAccessType[];
  products?: string[];
  user?: string;
}

export interface ContractProduct {
  id: string;
  displayName?: string;
}

export interface ContractLimits {
  products: ContractProductLimits[];
  package: ContractPackage;
}

export interface ContractProductLimits {
  limits: Record<string, number>;
  overrides: Record<string, number>;
  product: { id: string; displayName: string };
  usage: Record<string, number>;
}

export interface ContractsQuery {
  filters?: ContractsQueryFilters;
  page?: Page;
  sortBy?: ContractsQuerySortBy;
}

export interface ContractPackage {
  limits: { totalRoadNetwork: number; agreementJobs: number };
}

export type ContractOverlaps = {
  contract: ContractPartial;
  user: { email: string };
};

export type ContractsQuerySortBy = SortingRule<Contract>[];

export enum ContractType {
  Distribution = 'DISTRIBUTION',
  Evaluation = 'EVALUATION',
  Internal = 'INTERNAL',
  License = 'LICENSE',
}

export type ContractUser = Pick<UserDto, 'email' | 'role' | 'status'>;
export type ContractUserPartial = Partial<ContractUser> & { email: string };
