import { OrganizationDto, OrganizationTreeNode } from 'app/types/Organization';
import { UserOrganizationDto } from 'app/types/User';
import {
  MeDetails,
  UserRole,
  isMeDetailsFallback,
  useMeDetailsQuery,
} from 'legoland-sdk/dist/experimental';
import orderBy from 'lodash/orderBy';
import { useEffect, useState } from 'react';
import { useOrganizationListQuery } from './../api/organizationsApiHooks';

type OrgId = number;

function treeFromOrgs(orgs: OrganizationDto[]) {
  const idToOrg = new Map<OrgId, OrganizationDto>();
  orgs.forEach((o) => idToOrg.set(o.id, o));

  const idToChildren = new Map<OrgId, Set<OrgId>>();
  orgs.forEach(({ id, parentId }) => {
    if (!idToChildren.has(parentId)) {
      idToChildren.set(parentId, new Set());
    }
    if (parentId !== null) {
      idToChildren.get(parentId).add(id);
    }
  });

  const tree: OrganizationTreeNode[] = [];

  function createTreeNode(
    id: OrgId,
    parent: OrganizationTreeNode = null,
    depth = 0,
  ) {
    const children = idToChildren.get(id);
    const org = idToOrg.get(id);
    const node: OrganizationTreeNode = {
      contracts: org.contracts,
      id: org.id,
      name: org.name,
      sapId: org.sapId,
      children: [],
      depth,
      parent,
      users: org.users && org.users.sort(),
    };
    node.children = children
      ? orderBy(
          Array.from(children.values()).map((id) =>
            createTreeNode(id, node, depth + 1),
          ),
          [(org) => org.name.toLowerCase()],
          ['asc'],
        )
      : [];
    return node;
  }

  for (const org of orgs) {
    if (org.parentId === undefined || org.parentId === null) {
      tree.push(createTreeNode(org.id));
    }
  }

  return tree;
}

function organizationFromUserOrganization(
  userOrganization: UserOrganizationDto,
): OrganizationDto {
  return {
    contracts: null,
    id: userOrganization.id,
    name: userOrganization.name,
    users: null,
  };
}

export function useOrgTree() {
  const meDetails = useMeDetailsQuery().data?.meDetails;
  const orgListQuery = useOrganizationListQuery();
  const orgs = orgListQuery.data;

  const [orgTree, setAvailableOrgs] =
    useState<OrganizationTreeNode[]>(undefined);

  useEffect(() => {
    if (!orgs || !meDetails || isMeDetailsFallback(meDetails)) return;

    const newOrgs = JSON.parse(JSON.stringify(orgs));

    if (meDetails.role === UserRole.Distributor) {
      newOrgs.push(organizationFromUserOrganization(meDetails.organization));
    }

    setAvailableOrgs(treeFromOrgs(newOrgs));
  }, [meDetails, orgs]);

  useEffect(() => {
    orgListQuery.refetch();
  }, [
    meDetails?.isFallback,
    (meDetails as MeDetails)?.organization,
    meDetails?.role,
  ]);

  return [orgTree, orgListQuery.refetch] as const;
}
