import { UserRole } from 'legoland-sdk/dist/experimental';

export function requireRole(
  requiredRoles: UserRole | UserRole[],
  userRole: UserRole | null | undefined,
): boolean {
  if (Array.isArray(requiredRoles)) {
    return requiredRoles.some((role) => role === userRole);
  }
  return requiredRoles === userRole;
}
