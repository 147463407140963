import {
  createApiQueryHook,
  useApiMutation,
} from 'app/api/app-api/apiHooksHelpers';
import { useQueryClient } from 'react-query';

export function useAddContractMutation() {
  const queryClient = useQueryClient();
  return useApiMutation('addContract', {
    fetchFnName: 'getContractByUri',
    mutationOptions: {
      onSettled() {
        queryClient.invalidateQueries(['legoland', 'contracts']);
      },
    },
  });
}

export const useContractQuery = createApiQueryHook(
  ({ contractId }) => ['legoland', 'contracts', contractId],
  'getContract',
);

export const useContractListQuery = createApiQueryHook(
  (filters) => ['legoland', 'contracts', filters],
  'getContractList',
);

export const useContractUsersQuery = createApiQueryHook(
  ({ contractId }) => ['legoland', 'contracts', contractId, 'users'],
  'getContractUsers',
);

export function useSetContractUsersMutation() {
  const queryClient = useQueryClient();
  return useApiMutation('setContractUsers', {
    mutationOptions: {
      onSettled() {
        queryClient.invalidateQueries(['legoland', 'contracts']);
      },
    },
  });
}

export function useUpdateContractMutation() {
  const queryClient = useQueryClient();
  return useApiMutation('updateContract', {
    mutationOptions: {
      onSettled() {
        queryClient.invalidateQueries(['legoland', 'contracts', 'limits']);
      },
    },
  });
}

export const useContractLimitsQuery = createApiQueryHook(
  ({ contractId }) => ['legoland', 'contracts', 'limits', contractId],
  'getContractLimits',
);

export function useUpdateContractProductLimitMutation() {
  const queryClient = useQueryClient();
  return useApiMutation('setContractProductLimits', {
    mutationOptions: {
      onSettled() {
        queryClient.invalidateQueries(['legoland', 'contracts', 'limits']);
      },
    },
  });
}

export function useUpdateContractProductSharedLimitMutation() {
  const queryClient = useQueryClient();
  return useApiMutation('setContractProductSharedLimits', {
    mutationOptions: {
      onSettled() {
        queryClient.invalidateQueries(['legoland', 'contracts', 'limits']);
      },
    },
  });
}

export function useResolveContractOverlaps() {
  const queryClient = useQueryClient();
  return useApiMutation('resolveContractOverlaps', {
    mutationOptions: {
      onSettled() {
        queryClient.invalidateQueries(['legoland', 'contracts', 'limits']);
      },
    },
  });
}

export function useReassignContractUsers() {
  const queryClient = useQueryClient();
  return useApiMutation('reassignContractUsers', {
    mutationOptions: {
      onSettled() {
        queryClient.invalidateQueries(['legoland', 'contracts']);
      },
    },
  });
}
