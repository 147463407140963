import { LimitType } from 'legoland-sdk/dist/experimental';
import BooleanLimitEditor from './BooleanLimitEditor';
import CountLimitEditor from './CountLimitEditor';
import DateLimitEditor from './DateLimitEditor';
import DateRangeLimitEditor from './DateRangeLimitEditor';
import GeoLimitEditor from './GeoLimitEditor';
import KmLimitEditor from './KmLimitEditor';
import { LimitEditor } from './LimitEditorTypes';
import SquareKmLimitEditor from './SquareKmLimitEditor';

const limitToComponentMap: Record<LimitType, LimitEditor> = {
  [LimitType.Boolean]: BooleanLimitEditor,
  [LimitType.Count]: CountLimitEditor,
  [LimitType.Date]: DateLimitEditor,
  [LimitType.DateRange]: DateRangeLimitEditor,
  [LimitType.Geo]: GeoLimitEditor,
  [LimitType.Km]: KmLimitEditor,
  [LimitType.SquareKm]: SquareKmLimitEditor,
};

export function getLimitEditorComponent(type: LimitType) {
  if (!limitToComponentMap[type]) {
    throw new Error(`Limit component for type "${type}" does not exist.`);
  }
  return limitToComponentMap[type];
}
