import { AppApiPartProps } from 'app/api/app-api/createAppApiClient';
import { ModalLicenseFormValues } from './ModalLicenseForm';
import { ModalSupportFormValues } from './ModalSupportForm';

export type UserContactData = {
  company: string;
  country: string;
  email: string;
  firstName: string;
  industry: string;
  lastName: string;
  phone: string;
};

export type ContactFormProduct = {
  id: string;
  name: string;
};

export function dashboardApi({ baseUrl, get, postJson }: AppApiPartProps) {
  return {
    getProductsData() {
      return get<ContactFormProduct[]>(
        `${baseUrl}/contact-us/support/products`,
      );
    },

    getUserData() {
      return get<UserContactData>(`${baseUrl}/contacts/self`);
    },

    publishSellerForm({ values }: { values: ModalLicenseFormValues }) {
      return postJson(`${baseUrl}/contact-us/seller`, values);
    },

    publishSupportForm({ values }: { values: ModalSupportFormValues }) {
      return postJson(`${baseUrl}/contact-us/support`, values);
    },
  };
}
