import { useMemo } from 'react';
export function useCommunicationInitialValues(_ref) {
  let {
    communicationItem,
    initialProducts,
    initialRoles
  } = _ref;
  return useMemo(() => communicationItem ? {
    ...communicationItem,
    date: new Date(communicationItem.date)
  } : {
    date: new Date(),
    message: '',
    products: initialProducts ?? [],
    roles: initialRoles ?? []
  }, [communicationItem, initialProducts, initialRoles]);
}