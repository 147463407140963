import styled, { css } from 'styled-components';
import { propStyling, tombac } from 'tombac';
export const Table = styled.table.withConfig({
  displayName: "Table"
})(["border-spacing:0;border-collapse:separate;min-width:100%;table-layout:fixed;width:100%;border:", ";border-radius:10px 10px 0 0;overflow:hidden;", ""], tombac.parse('1u solid --neutral-400'), propStyling);
export const Thead = styled.thead.withConfig({
  displayName: "Table__Thead"
})(["tr{height:40px;}"]);
export const Tbody = styled.tbody.withConfig({
  displayName: "Table__Tbody"
})(["background-color:", ";"], tombac.color('neutral', 100));
export const Th = styled.th.withConfig({
  displayName: "Table__Th"
})(["border-bottom:", ";padding:", ";background-color:", ";text-align:left;white-space:nowrap;", " ", " &:first-child{padding-left:", ";}&:last-child{padding-right:", ";}"], tombac.parse('1u solid --neutral-300'), tombac.parse('10u 1.5sp'), tombac.color('neutral', 300), _ref => {
  let {
    sticky
  } = _ref;
  return sticky || typeof sticky === 'number' ? css(["position:sticky;top:0;"]) : undefined;
}, tombac.altText({
  color: tombac.color('neutral', 700),
  fontSize: 12,
  fontWeight: 'bold'
}), tombac.space(3), tombac.space(3));
export const Td = styled.td.withConfig({
  displayName: "Table__Td"
})(["border-bottom:", ";padding:", ";text-align:left;text-overflow:", ";white-space:", ";", ";&:first-child{padding-left:", ";}&:last-child{padding-right:", ";}", ""], tombac.parse('1u solid --neutral-300'), tombac.space(0, 1.5), _ref2 => {
  let {
    $textOverflow = 'ellipsis'
  } = _ref2;
  return $textOverflow;
}, _ref3 => {
  let {
    $whiteSpace
  } = _ref3;
  return $whiteSpace;
}, tombac.text({
  color: tombac.color('neutral', 700),
  fontSize: 14
}), tombac.space(3), tombac.space(3), propStyling);
export const Tr = styled.tr.withConfig({
  displayName: "Table__Tr"
})(["all:initial;display:table-row;transition:background-color 0.1s ease-out;vertical-align:middle;height:56px;&:hover{background-color:", ";}&:last-child > ", "{border-bottom:none;}", ""], tombac.color('neutral', 200), Td, propStyling);