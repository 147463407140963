type Some<R> = {
  value: R;
};

type Response<R> = Some<R> | Error;

const of = <R>(r: R): Response<R> => ({ value: r });

const ofError = <R>(error: Error): Response<R> => error;

const map = <A, B>(o: Response<A>, mapper: (r: A) => B): Response<B> => {
  if (present(o)) {
    return of(mapper(o.value));
  }
  return o as Response<B>;
};

const get = <A>(o: Response<A>): A => {
  return (o as Some<A>).value;
};

const getError = (o: Response<any>): Error => {
  return o as Error;
};

const present = <R>(r: Response<R>): r is Some<R> => !(r instanceof Error);

const match = <A>(
  o: Response<A>,
  whenPresent: (some: A) => any,
  whenError: (error: Error) => any,
) => {
  if (present(o)) {
    return whenPresent(o.value);
  } else {
    return whenError(o);
  }
};

const fromPromise = async function <R>(promise: Promise<R>) {
  let result: R;
  try {
    result = await promise;
  } catch (message) {
    return new Error(message);
  }
  return Response.of(result);
};

const getOrThrow = <R>(r: Response<R>) => {
  if (!present(r)) {
    throw r;
  }
  return get(r);
};

export const Response = {
  fromPromise,
  of,
  ofError,
  map,
  present,
  get,
  getError,
  match,
  getOrThrow,
};

export default Response;
