import {
  init,
  reactRouterV5BrowserTracingIntegration,
  replayIntegration,
} from '@sentry/react';
import { browserHistory } from 'core/App/track';

if (process.env.SENTRY_DSN) {
  init({
    debug: false, // process.env.APP_ENV === 'local',
    dsn: process.env.SENTRY_DSN,
    enabled: process.env.APP_ENV !== 'local',
    environment: process.env.APP_ENV,
    integrations: [
      reactRouterV5BrowserTracingIntegration({ history: browserHistory }),
      replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],

    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 10% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/.*\.api\.move\.tomtom\.com\//,
    ],

    // Session Replay
    replaysSessionSampleRate: 0.01, // This sets the sample rate at 1%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
