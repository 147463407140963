import { createAsyncHook } from 'app/hooks/useAsync';
import { RegistrationFormValues } from 'app/types/User';
import defaultApiClient from '../modules/core/apiClient';

export function getCountries(): Promise<string[]> {
  return defaultApiClient.jsonRequest('/countries');
}

export function getIndustries(): Promise<string[]> {
  return defaultApiClient.jsonRequest('/contacts/industries');
}

export function registerUser(userData: RegistrationFormValues) {
  return defaultApiClient.request('/registrations', {
    body: JSON.stringify(userData),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });
}

export const useCountries = createAsyncHook(getCountries);
export const useIndustries = createAsyncHook(getIndustries);
