import {
  createApiQueryHook,
  useApiMutation,
} from 'app/api/app-api/apiHooksHelpers';
import { useQuery, useQueryClient } from 'react-query';
import { useAppApiClient } from '../../api/app-api/AppApiClientContext';

export function useRequestReportMutation() {
  const queryClient = useQueryClient();
  return useApiMutation('requestReport', {
    mutationOptions: {
      onSettled() {
        queryClient.invalidateQueries(['legoland', 'reports', 'status']);
      },
    },
  });
}

export const useReportStatusQuery = createApiQueryHook(
  () => ['legoland', 'reports', 'status'],
  'getReportStatus',
);

export function useDownloadReportQuery(options?) {
  const apiClient = useAppApiClient();
  return useQuery(
    ['legoland', 'reports', 'download'],
    async () => {
      const response = await apiClient.getReport();
      return response;
    },
    { enabled: false, ...options },
  );
}

export function useDeleteProcessingReport() {
  const queryClient = useQueryClient();
  return useApiMutation('deleteProcessingReport', {
    mutationOptions: {
      onSettled() {
        queryClient.invalidateQueries(['legoland', 'reports', 'status']);
      },
    },
  });
}

export const useReportsList = createApiQueryHook(
  ({ email, pagination, product }: any) => [
    'legoland',
    'reports',
    'list',
    email,
    pagination,
    product,
  ],
  'getReportsList',
);

export function useCopyReportMutation() {
  const queryClient = useQueryClient();
  return useApiMutation('copyReportAppData', {
    mutationOptions: {
      onSettled() {
        queryClient.invalidateQueries(['legoland', 'reports', 'list']);
      },
    },
  });
}
