import defaultApiClient from '../modules/core/apiClient';
import { OrganizationDto } from '../types/Organization';

type SaveOrganizationRequest = Partial<
  Pick<OrganizationDto, 'name' | 'parentId' | 'sapId'>
>;

const update = async (id: number, saveRequest: SaveOrganizationRequest) =>
  defaultApiClient.request('/organizations/' + encodeURIComponent(id), {
    method: 'PATCH',
    body: JSON.stringify(saveRequest),
  });

const deleteOrg = (id: number) =>
  defaultApiClient.request(`/organizations/${encodeURIComponent(id)}`, {
    method: 'DELETE',
  });

const get = (id: number) =>
  defaultApiClient.jsonRequest<OrganizationDto>(
    `/organizations/${encodeURIComponent(id)}`,
  );

const OrganizationApi = {
  delete: deleteOrg,
  get,
  update,
};

export default OrganizationApi;
