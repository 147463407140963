import { UserRole } from 'legoland-sdk/dist/experimental';
import { InferType, array, date, object, string } from 'yup';

export const communicationValidationSchema = object({
  date: date().required('Date is required'),
  message: string().required('Message is required'),
  products: array<string>().min(1, 'At least one product is required'),
  roles: array<UserRole>().min(1, 'At least one role is required'),
});

export type CommunicationFormValues = InferType<
  typeof communicationValidationSchema
>;
