import {
  ClientOptions,
  ClientQueryHook,
  ClientReturnType,
  createApiQueryHook,
  createMutationResponseHandler,
  getQueryResult,
} from 'app/api/app-api/apiHooksHelpers';
import { User } from 'app/types/User';
import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { useAppApiClient } from '../../../api/app-api/AppApiClientContext';

export const useAccessHistoryQuery: ClientQueryHook<
  'getAccessHistory',
  'history'
> = (options, queryOptions?) => {
  const apiClient = useAppApiClient();
  return useQuery(
    ['legoland', 'access', 'history', options],
    async () => {
      const response = await apiClient.getAccessHistory(options);
      const history = await getQueryResult(response);
      return { history, response };
    },
    queryOptions,
  );
};

export const useAccessHistoryByProductQuery: ClientQueryHook<
  'getAccessHistoryByProduct',
  'history'
> = (options, queryOptions?) => {
  const apiClient = useAppApiClient();
  return useQuery(
    ['legoland', 'access', 'history', options],
    async () => {
      const response = await apiClient.getAccessHistoryByProduct(options);
      const history = await getQueryResult(response);
      return { history, response };
    },
    queryOptions,
  );
};

export const useActivationLinkQuery = (
  options: ClientOptions<'getActivationLink'>,
  queryOptions?: UseQueryOptions<{ activationLink: string }>,
) => {
  const apiClient = useAppApiClient();
  return useQuery(
    ['legoland', 'activation-link', options],
    async () => {
      const response = await apiClient.getActivationLink(options);
      const { activationLink } = await getQueryResult(response);
      return { activationLink, response };
    },
    queryOptions,
  );
};

export const useAddAccessMutation = () => {
  const apiClient = useAppApiClient();
  const queryClient = useQueryClient();
  return useMutation(createMutationResponseHandler(apiClient.addAccess), {
    onSettled: (data, error, { username }) => {
      queryClient.invalidateQueries(['legoland', 'access']);
      queryClient.invalidateQueries(['legoland', 'limits', username]);
      queryClient.invalidateQueries(['legoland', 'users', username]);
    },
  });
};

export const useAddUserMutation = () => {
  const apiClient = useAppApiClient();
  const queryClient = useQueryClient();
  return useMutation(createMutationResponseHandler(apiClient.addUser), {
    onSettled: () => {
      queryClient.invalidateQueries(['legoland', 'users']);
    },
  });
};

export const useApigeeDeveloperQuery: ClientQueryHook<
  'getApigeeDeveloper',
  'developer'
> = (options, queryOptions?) => {
  const apiClient = useAppApiClient();
  return useQuery(
    ['legoland', 'apigee-developer', options],
    async () => {
      const response = await apiClient.getApigeeDeveloper(options);
      const developer = await getQueryResult(response);
      return { developer, response };
    },
    queryOptions,
  );
};

export const useApigeeDeveloperMutation = () => {
  const apiClient = useAppApiClient();
  const queryClient = useQueryClient();
  return useMutation(
    createMutationResponseHandler(apiClient.updateApigeeDeveloper),
    {
      onSettled: (data, error, { username }) => {
        queryClient.invalidateQueries([
          'legoland',
          'apigee-developer',
          { username },
        ]);
      },
    },
  );
};

export const useChangeEmailMutation = () => {
  const apiClient = useAppApiClient();
  return useMutation(createMutationResponseHandler(apiClient.changeEmail));
};

export const useRotateApigeeDeveloperKey = () => {
  const apiClient = useAppApiClient();
  const queryClient = useQueryClient();
  return useMutation(
    createMutationResponseHandler(apiClient.rotateApigeeDeveloperKey),
    {
      onSettled: (data, error, { username }) => {
        queryClient.invalidateQueries([
          'legoland',
          'apigee-developer',
          { username },
        ]);
      },
    },
  );
};

export const useCheckApiKeyQuery: ClientQueryHook<'checkApiKey', 'result'> = (
  { productId, apiKey },
  queryOptions?,
) => {
  const apiClient = useAppApiClient();
  return useQuery(
    ['legoland', 'checkApiKey', productId, apiKey],
    async () => {
      const response = await apiClient.checkApiKey({ productId, apiKey });
      const result = await getQueryResult(response);
      return { response, result };
    },
    queryOptions,
  );
};

export const useRemoveAccessMutation = () => {
  const apiClient = useAppApiClient();
  const queryClient = useQueryClient();
  return useMutation(createMutationResponseHandler(apiClient.removeAccess), {
    onSettled: (data, error, { username }) => {
      queryClient.invalidateQueries(['legoland', 'access']);
      queryClient.invalidateQueries(['legoland', 'limits', username]);
      queryClient.invalidateQueries(['legoland', 'users', username]);
    },
  });
};

export const useUserMutation = () => {
  const apiClient = useAppApiClient();
  const queryClient = useQueryClient();
  return useMutation(createMutationResponseHandler(apiClient.updateUser), {
    onSettled(data, error, { update: { email } }) {
      queryClient.invalidateQueries(['legoland', 'access']);
      queryClient.invalidateQueries(['legoland', 'users', email]);
    },
  });
};

export const useUserQuery = (
  { username }: { username: string },
  queryOptions?: UseQueryOptions<{
    user: User;
    response: ClientReturnType<'getUserList'>;
  }>,
) => {
  const apiClient = useAppApiClient();
  return useQuery(
    ['legoland', 'users', username],
    async () => {
      const response = await apiClient.getUserList({ username } as any);
      const userCollection = await getQueryResult(response);
      const user = userCollection.content[0] ?? null;
      return { response, user };
    },
    queryOptions,
  );
};

export const useUserListReportQuery = createApiQueryHook(
  (query) => ['legoland', 'usersReport', query],
  'getUsersListReports',
);
