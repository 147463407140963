import { requireRole } from 'core/authHelpers';
import {
  AccessType,
  useLimitSchemasQuery,
  useMeQuery,
  UserRole,
} from 'legoland-sdk/dist/experimental';
import { orderBy } from 'lodash';
import { useMemo } from 'react';
import { useToasts } from 'tombac';
import { useLimitDefaultsQuery } from './api/limitsApiHooks';

export function useProductLimitDefaults({
  accessType,
  productId,
}: {
  accessType: AccessType;
  productId: string;
}) {
  const { addToast } = useToasts();
  const defaultsQuery = useLimitDefaultsQuery();

  if (defaultsQuery.isError) {
    addToast('Could not fetch limit defaults.', 'danger');
    console.error(defaultsQuery.error);
  }

  return useMemo(() => {
    return defaultsQuery.data?.defaults?.find(
      (i) => i.accessType === accessType && i.productId === productId,
    )?.limit;
  }, [defaultsQuery.data?.defaults]);
}

export function useProductLimitSchemas({ productId }: { productId: string }) {
  const { addToast } = useToasts();

  const meQuery = useMeQuery();
  const currentUser = meQuery.data?.me;

  const schemaQuery = useLimitSchemasQuery();
  const schemas = schemaQuery.data?.schemas;

  if (meQuery.isError || schemaQuery.isError) {
    addToast('Could not fetch limit schemas.', 'danger');
    console.error(meQuery.error ?? schemaQuery.error);
  }

  return useMemo(() => {
    if (!schemas) return schemas;
    const unorderedSchemas = schemas.filter((i) => i.productId === productId);
    return orderBy(unorderedSchemas, ['displayOrder']).filter(
      ({ customizable, requiredRole }) =>
        customizable &&
        (currentUser?.role === UserRole.Admin ||
          currentUser?.role === UserRole.Support ||
          requireRole(requiredRole, currentUser?.role)),
    );
  }, [currentUser, productId, schemas]);
}
