import Styled from 'app/modules/registration/StyledRegistrationUi';
import defaultRecoverClient from 'auth/recoverClient';
import defaultRegisterClient from 'auth/registerClient';
import { LoadingSpinner } from 'core/components/LoadingSpinner';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import {
  ActivateError,
  LinkExpired,
  SubscriptionActivated,
  UserSubmitted,
} from './ActivationVariants';
import { SetPasswordModes } from './LoginTypes';
import SetPasswordForm from './SetPasswordForm';

const SetPasswordPopup = ({
  location: { search },
  mode,
  registerClient,
  recoverClient,
}) => {
  const [userStatus, setUserStatus] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [activateError, setActivateError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(function verifyToken() {
    setIsLoading(true);
    apiClient
      .verifyToken(search)

      .then((res) => {
        setUserStatus(res);
        if (res?.user?.status === 'ACTIVE') {
          apiClient
            .submitUserData(search)
            .then(() => {
              setIsLoading(false);
            })
            .catch((e) => {
              setIsLoading(false);
              setActivateError(e);
            });
        } else {
          setIsLoading(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setIsExpired(true);
        setErrorMessage(e);
      });
  }, []);

  let messageId = !isSubmitted
    ? 'auth.set-password.set-your-password'
    : 'auth.set-password.password-changed';

  // messageId = isExpired ? 'auth.set-password.token-expired' : messageId;

  const apiClient =
    mode === SetPasswordModes.Register ? registerClient : recoverClient;

  if (isLoading) return <LoadingSpinner />;

  return (
    <Styled.Page>
      <Styled.Content>
        {userStatus?.user.status === 'INACTIVE' && (
          <>
            {!isSubmitted && (
              <>
                <Styled.Heading level={1} $mb="1sp">
                  Thank you! Your account has been activated.
                </Styled.Heading>
                <Styled.Heading as="p" level={2} $mb="4sp">
                  Please, set your password.
                </Styled.Heading>
                <div className="LoginForm">
                  <SetPasswordForm
                    setSubmitted={setIsSubmitted}
                    apiClient={apiClient}
                    query={search}
                    mode={mode}
                  />
                </div>
              </>
            )}
          </>
        )}
        {!isExpired &&
          !activateError &&
          userStatus?.user.status === 'ACTIVE' && (
            <SubscriptionActivated source={userStatus.source} />
          )}
        {isSubmitted && <UserSubmitted mode={mode} />}
        {isExpired && <LinkExpired errorMessage={errorMessage} />}
        {activateError && <ActivateError error={activateError} />}
        {!userStatus && !isExpired && !isSubmitted && (
          <div className="SetPasswordPopup LoginPopup__modal py-5">
            <h1 className="LoginPopup__title">
              <FormattedHTMLMessage id={messageId} />
            </h1>

            <SetPasswordForm
              setSubmitted={setIsSubmitted}
              apiClient={apiClient}
              query={search}
            />
          </div>
        )}
      </Styled.Content>
    </Styled.Page>
  );
};

SetPasswordPopup.propTypes = {
  onClick: PropTypes.func,
  location: PropTypes.object,
  recoverClient: PropTypes.object,
  registerClient: PropTypes.object,
  mode: PropTypes.oneOf([SetPasswordModes.Register, SetPasswordModes.Recover]),
};

SetPasswordPopup.defaultProps = {
  recoverClient: defaultRecoverClient,
  registerClient: defaultRegisterClient,
};

export default SetPasswordPopup;
