import { AccessHistoryStatus } from 'app/types/User';
import { ElementType } from 'react';
import { ColorGrade, ThemeColor } from 'tombac';
import {
  AcceptIcon,
  ErrorIcon,
  ProcessingIcon,
  ValidationIcon,
} from 'tombac-icons';

export const statusIconMap: Record<
  AccessHistoryStatus,
  { color: [ThemeColor, ColorGrade]; icon: ElementType }
> = {
  [AccessHistoryStatus.Active]: {
    color: ['success', 600],
    icon: AcceptIcon,
  },
  [AccessHistoryStatus.Expired]: {
    color: ['danger', 500],
    icon: ErrorIcon,
  },
  [AccessHistoryStatus.Pending]: {
    color: ['alert', 600],
    icon: ProcessingIcon,
  },
  [AccessHistoryStatus.Terminated]: {
    color: ['neutral', 500],
    icon: ValidationIcon,
  },
};
