import {
  UserProduct,
  UserProductFallback,
} from 'legoland-sdk/dist/experimental';
import { createContext } from 'react';

export interface DashboardProductsContextType {
  products: (UserProduct | UserProductFallback)[];
  reloadProducts: () => void;
}

export default createContext<DashboardProductsContextType>(undefined);
