import isomorphicFetch from 'isomorphic-fetch';

import config from './config';

export const CODES = {
  SUCCESS: 200,
  CREATED: 201,
  UNAUTORIZED: 401,
  CONFLICT: 409,
  APPLICATION_ERROR: 500,
};

export const BASE_URL = config.API_URL;
export const AUTH_BASE_URL = config.AUTH_API_URL;

function createClient(fetch: typeof isomorphicFetch) {
  async function makeRequest(url: string, options?: RequestInit) {
    const allOptions: RequestInit = {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      credentials: 'include',
      ...options,
    };
    return await fetch(url, allOptions);
  }

  async function handleResponse(
    response: Response,
    { handleUnauthorized = false } = {},
  ) {
    if (handleUnauthorized && response.status === CODES.UNAUTORIZED) {
      return Promise.reject();
    }
    if (response.status >= 400) {
      const json = await response.json();
      return Promise.reject(json.message);
    }
    return Promise.resolve(response);
  }

  return {
    async authRequest(endpoint: string, options?: RequestInit) {
      return handleResponse(
        await makeRequest(AUTH_BASE_URL + endpoint, options),
        {
          handleUnauthorized: true,
        },
      );
    },

    async request(endpoint: string, options?: RequestInit) {
      return handleResponse(await makeRequest(BASE_URL + endpoint, options), {
        handleUnauthorized: true,
      });
    },

    async jsonRequest<ResponseType = any>(
      endpoint: string,
      options?: RequestInit,
    ) {
      const response = await this.request(endpoint, options);
      const json = await response.json();
      return json as ResponseType;
    },

    async productRequest(
      productId: string,
      endpoint: string,
      options: RequestInit = {},
    ) {
      return handleResponse(
        await makeRequest(config.PRODUCT_API_URL[productId] + endpoint, {
          credentials: 'omit',
          ...options,
        }),
      );
    },
  };
}

export default createClient(isomorphicFetch);
