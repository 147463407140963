import styled from 'styled-components';
import { propStyling, tombac } from 'tombac';
const PagePanel = styled.div.attrs(_ref => {
  let {
    breakpoint
  } = _ref;
  return {
    breakpoint: breakpoint ?? 1440,
    className: 'PagePanel'
  };
}).withConfig({
  displayName: "PagePanel"
})(["background-color:", ";border-radius:", ";padding:", ";margin:", " auto;max-width:", ";@media (max-width:", "){border:", " solid ", ";border-left:0;border-radius:0;border-right:0;}", ""], tombac.color.white, tombac.unit(20), tombac.space(3), tombac.space(3), tombac.unit(1440), _ref2 => {
  let {
    breakpoint
  } = _ref2;
  return tombac.unit(breakpoint);
}, tombac.unit(1), tombac.color('neutral', 400), propStyling);
export default PagePanel;