import styled from 'styled-components';
import { propStyling, tombac } from 'tombac';
const Root = styled.div.withConfig({
  displayName: "HorizontalForm__Root"
})(["display:flex;margin-left:", ";", ""], tombac.space(-3), propStyling);
const Group = styled.div.withConfig({
  displayName: "HorizontalForm__Group"
})(["border-right:", ";padding:", ";", ""], tombac.parse('1u solid --neutral-300'), tombac.space(0, 3), propStyling);
export const HorizontalForm = {
  Group,
  Root
};