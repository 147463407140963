export function constantToDisplayName(constant: string) {
  switch (constant) {
    case 'GRANTED':
      return 'Active';
    case 'API ONLY':
    case 'API_ONLY':
      return 'API only';
    default:
      return (
        constant.charAt(0).toUpperCase() +
        constant.slice(1).toLowerCase().replace(/_/g, ' ')
      );
  }
}
