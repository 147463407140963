import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';
import { trackEvent } from 'legoland-sdk';

export const browserHistory = createBrowserHistory();

const reactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({
  config: {
    disableTelemetry: process.env.APP_ENV !== 'prod',
    disableFetchTracking: false,
    maxBatchInterval: 5000,
    instrumentationKey: '96534833-7eef-48ad-84e4-4d459a5e3df0',
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
appInsights.loadAppInsights();
appInsights.addTelemetryInitializer((item) => {
  const ignoreItems = ['authenticate/refresh-token', 'vector-maps-e45'];
  if (
    item.baseData.target &&
    ignoreItems.findIndex((target) => item.baseData.target.includes(target)) >
      -1
  ) {
    return false;
  }
});

export { trackEvent as track };
