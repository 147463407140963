import { EffectCallback, useCallback, useEffect, useState } from 'react';

/**
 * Same as useEffect, but returns a function, which will call the effect on-demand even when deps have not changed.
 */
export default function useCallableEffect(effect: EffectCallback, deps: any[]) {
  const [callValue, setCallValue] = useState({});
  const call = useCallback(() => setCallValue({}), []);
  useEffect(effect, [...deps, callValue]);
  return call;
}
