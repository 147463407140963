import { communicationsApi } from 'app/modules/communications/communicationsApi';
import { dashboardApi } from 'dashboard/DashboardContactForm/dashboardApi';
import { contractsApi } from '../../modules/contracts/contractsApi';
import { organizationsApi } from '../../modules/organizations/api/organizationsApi';
import { limitsApi } from '../../modules/products/api/limitsApi';
import { reportsApi } from '../../modules/reports/reportsApi';
import { userManagementApi } from '../../modules/users/api/userManagementApi';
import { usersApi } from '../../modules/users/list/userApi';
import { AppApiPart } from './createAppApiClient';

export const apiParts = [
  communicationsApi,
  contractsApi,
  dashboardApi,
  limitsApi,
  organizationsApi,
  userManagementApi,
  reportsApi,
  usersApi,
] satisfies AppApiPart[];
