import {
  AccessType,
  UserProduct,
  UserProductFallback,
  UserProductSafe,
} from 'legoland-sdk/dist/experimental';

export interface ProductUpdateDto {
  productId: string;
  expiryDate: Date;
  accessType: AccessType;
  apiKey?: string;
}

export interface ResellProductUpdateDto {
  expiryDate: Date | string | null;
  productId: string;
}

export const isViewOnlyProduct = (p: UserProduct | UserProductFallback) =>
  p.access.status === 'DENIED' || p.access.status === 'REVOKED';

export const productFormFromProduct = (
  p: UserProductSafe,
): ProductUpdateDto => {
  return {
    accessType: (p.access as any).accessType || AccessType.Evaluation,
    apiKey: (p.access as any).apiKey,
    expiryDate: (p.access as any).expiryDate,
    productId: p.id,
  };
};

export const resellProductFromProduct = (
  p: UserProduct,
): ResellProductUpdateDto => {
  return {
    expiryDate:
      p.resell.status === 'ALLOWED' && p.resell.expiryDate
        ? p.resell.expiryDate
        : p.access.status !== 'DENIED'
        ? p.access.expiryDate
        : null,
    productId: p.id,
  };
};
