import { useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';
export function FormikEvent(_ref) {
  let {
    onChange,
    onValuesChange
  } = _ref;
  const context = useFormikContext();
  const onChangeRef = useRef(onChange);
  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);
  const onValuesChangeRef = useRef(onValuesChange);
  useEffect(() => {
    onValuesChangeRef.current = onValuesChange;
  }, [onValuesChange]);
  useEffect(() => {
    onChangeRef.current?.(context);
  }, [context]);
  useEffect(() => {
    onValuesChangeRef.current?.(context.values);
  }, [context.values]);
  return null;
}