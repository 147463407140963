import cx from 'classnames';
import { Component } from 'react';
import { Button } from 'tombac';
import cookieManager from './cookieManager';

import './CookieBar.css';

const wasActedUponBefore = cookieManager.isActedUpon();

export default class CookieBar extends Component {
  constructor() {
    super();

    this.state = {
      isActedUpon: cookieManager.isActedUpon(),
      isAccepted: cookieManager.isAccepted(),
    };

    this.accept = this.accept.bind(this);
    this.decline = this.decline.bind(this);
  }

  accept() {
    cookieManager.accept();
    this.setState({
      isActedUpon: true,
      isAccepted: true,
    });
  }

  decline() {
    cookieManager.decline();
    this.setState({
      isActedUpon: true,
      isAccepted: false,
    });
  }

  render() {
    if (wasActedUponBefore) {
      return null;
    }

    const { isActedUpon } = this.state;

    const className = cx('CookieBar', {
      'CookieBar--closed': isActedUpon,
    });

    return (
      <div className={className}>
        <div className="CookieBar__text">
          At TomTom, we thrive on helping our customers. That&apos;s why we use
          cookies. They help us improve our websites, offer information based on
          your interests, and improve our interactions on social media. Find out
          more at{' '}
          <a
            className="CookieBar__link"
            href="https://www.tomtom.com/en_gb/cookies/"
          >
            tomtom.com/cookies
          </a>
        </div>

        <div className="CookieBar__buttons">
          <Button
            data-testid="CookieBar__accept"
            onClick={this.accept}
            variant="primary"
          >
            Accept
          </Button>
          <div
            className="CookieBar__link CookieBar__decline"
            onClick={this.decline}
          >
            Decline
          </div>
        </div>
      </div>
    );
  }
}
