import { AccessType } from 'legoland-sdk/dist/experimental';
import { ElementType } from 'react';
import {
  DemoIcon,
  MaintenanceIcon,
  PaidIcon,
  TimeIcon,
  TtLogoIcon,
} from 'tombac-icons';

export const accessIconMap: Record<AccessType, ElementType> = {
  [AccessType.CustomAgreement]: MaintenanceIcon,
  [AccessType.Evaluation]: TimeIcon,
  [AccessType.Demo]: DemoIcon,
  [AccessType.Internal]: TtLogoIcon,
  [AccessType.Paying]: PaidIcon,
};
