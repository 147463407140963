import {
  LegolandApiClientConfig,
  TypedJsonResponse,
} from 'legoland-sdk/dist/experimental';
import { apiParts } from './apiParts';

type ApiFetchReturnType<T> = T extends undefined
  ? Promise<Response>
  : Promise<TypedJsonResponse<T>>;

export interface AppApiPartProps extends LegolandApiClientConfig {
  /**
   * Make a request to the API.
   *
   * The parameters are passed to the standard `fetch` function.
   * The `init` is prefilled with `credentials: 'include'` for authentication to work correctly.
   *
   * This function lets you configure the request however you want, just like an ordinary `fetch` function.
   * For most of the requests, it is better to use specialized functions like `get`, `del`, `postJson`, and others.
   * They configure most of the request for you.
   *
   * You may provide a type for the `T` generic to resolve to a `TypedJsonResponse`
   * (otherwise it resolves to a standard `Response`). The response is not decoded for you.
   * You should still use `response.json()` to retrieve an object (the object will have the provided type).
   * You should not use it if you do not expect a JSON response.
   *
   * ```
   * const response = await apiFetch<User>(`${baseUrl}/users/${username}`, {
   *   method: "PUT",
   *   headers: { "Content-Type": "application/json" },
   *   body: JSON.stringify(updatedUser),
   * });
   *
   * const user = await response.json();
   * // `user` constant has the type `User`
   * ```
   */
  apiFetch<T = undefined>(
    input: RequestInfo,
    init?: RequestInit,
  ): ApiFetchReturnType<T>;

  /**
   * Make a DELETE request to the API.
   *
   * You may provide a type for the `T` generic to resolve to a `TypedJsonResponse`
   * (otherwise it resolves to a standard `Response`). The response is not decoded for you.
   * You should still use `response.json()` to retrieve an object (the object will have the provided type).
   * You should not use it if you do not expect a JSON response.
   *
   * ```
   * await del(`${baseUrl}/users/${username}`);
   * ```
   */
  del<T = undefined>(
    input: RequestInfo,
    init?: RequestInit,
  ): ApiFetchReturnType<T>;

  /**
   * Make a GET request to the API.
   *
   * The parameters are passed to the standard `fetch` function.
   * The `init` is prefilled with options adequate for a GET request.
   *
   * You may provide a type for the `T` generic to resolve to a `TypedJsonResponse`
   * (otherwise it resolves to a standard `Response`). The response is not decoded for you.
   * You should still use `response.json()` to retrieve an object (the object will have the provided type).
   * You should not use it if you do not expect a JSON response.
   *
   * ```
   * const response = get<User>(`${baseUrl}/users/${username}`);
   * const user = response.json();
   * // `user` constant has the type `User`
   * ```
   */
  get<T = undefined>(
    input: RequestInfo,
    init?: RequestInit,
  ): ApiFetchReturnType<T>;

  /**
   * Make a PATCH request to the API.
   *
   * The parameters are passed to the standard `fetch` function.
   * The `init` is prefilled with options adequate for a PATCH request.
   *
   * If you want to send a JSON with the request, try `patchJson` instead.
   *
   * You may provide a type for the `T` generic to resolve to a `TypedJsonResponse`
   * (otherwise it resolves to a standard `Response`). The response is not decoded for you.
   * You should still use `response.json()` to retrieve an object (the object will have the provided type).
   * You should not use it if you do not expect a JSON response.
   *
   * ```
   * const response = patch<User>(`${baseUrl}/users/${username}`, {
   *   headers: { "Content-Type": "application/json" },
   *   body: JSON.stringify(updatedUser),
   * });
   * const user = response.json();
   * // `user` constant has the type `User`
   * ```
   */
  patch<T = undefined>(
    input: RequestInfo,
    init?: RequestInit,
  ): ApiFetchReturnType<T>;

  /**
   * Make a PATCH request with a JSON payload to the API.
   *
   * The `input` and `init` parameters are passed to the standard `fetch` function.
   * The `init` is prefilled with options adequate for a PATCH request containing JSON as a body.
   * The `body` parameter can be any object that is convertable to JSON using the `JSON.stringify()` function.
   *
   * You may provide a type for the `T` generic to resolve to a `TypedJsonResponse`
   * (otherwise it resolves to a standard `Response`). The response is not decoded for you.
   * You should still use `response.json()` to retrieve an object (the object will have the provided type).
   * You should not use it if you do not expect a JSON response.
   *
   * ```
   * const response = patchJson<User>(`${baseUrl}/users/${username}`, {
   *   email: 'john.doe@example.com'
   * });
   * const user = response.json();
   * // `user` constant has the type `User`
   * ```
   */
  patchJson<T = undefined>(
    input: RequestInfo,
    body: unknown,
    init?: RequestInit,
  ): ApiFetchReturnType<T>;

  /**
   * Make a POST request to the API.
   *
   * The parameters are passed to the standard `fetch` function.
   * The `init` is prefilled with options adequate for a POST request.
   *
   * If you want to send a JSON with the request, try `postJson` instead.
   *
   * You may provide a type for the `T` generic to resolve to a `TypedJsonResponse`
   * (otherwise it resolves to a standard `Response`). The response is not decoded for you.
   * You should still use `response.json()` to retrieve an object (the object will have the provided type).
   * You should not use it if you do not expect a JSON response.
   *
   * ```
   * const response = post<User>(`${baseUrl}/users/${username}`, {
   *   headers: { "Content-Type": "application/json" },
   *   body: JSON.stringify(updatedUser),
   * });
   * const user = response.json();
   * // `user` constant has the type `User`
   * ```
   */
  post<T = undefined>(
    input: RequestInfo,
    init?: RequestInit,
  ): ApiFetchReturnType<T>;

  /**
   * Make a POST request with a JSON payload to the API.
   *
   * The `input` and `init` parameters are passed to the standard `fetch` function.
   * The `init` is prefilled with options adequate for a POST request containing JSON as a body.
   * The `body` parameter can be any object that is convertable to JSON using the `JSON.stringify()` function.
   *
   * You may provide a type for the `T` generic to resolve to a `TypedJsonResponse`
   * (otherwise it resolves to a standard `Response`). The response is not decoded for you.
   * You should still use `response.json()` to retrieve an object (the object will have the provided type).
   * You should not use it if you do not expect a JSON response.
   *
   * ```
   * const response = postJson<User>(`${baseUrl}/users/${username}`, {
   *   email: 'john.doe@example.com'
   * });
   * const user = response.json();
   * // `user` constant has the type `User`
   * ```
   */
  postJson<T = undefined>(
    input: RequestInfo,
    body: unknown,
    init?: RequestInit,
  ): ApiFetchReturnType<T>;

  /**
   * Make a PUT request to the API.
   *
   * The parameters are passed to the standard `fetch` function.
   * The `init` is prefilled with options adequate for a PUT request.
   *
   * If you want to send a JSON with the request, try `putJson` instead.
   *
   * You may provide a type for the `T` generic to resolve to a `TypedJsonResponse`
   * (otherwise it resolves to a standard `Response`). The response is not decoded for you.
   * You should still use `response.json()` to retrieve an object (the object will have the provided type).
   * You should not use it if you do not expect a JSON response.
   *
   * ```
   * const response = put<User>(`${baseUrl}/users/${username}`, {
   *   headers: { "Content-Type": "application/json" },
   *   body: JSON.stringify(updatedUser),
   * });
   * const user = response.json();
   * // `user` constant has the type `User`
   * ```
   */
  put<T = undefined>(
    input: RequestInfo,
    init?: RequestInit,
  ): ApiFetchReturnType<T>;

  /**
   * Make a PUT request with a JSON payload to the API.
   *
   * The `input` and `init` parameters are passed to the standard `fetch` function.
   * The `init` is prefilled with options adequate for a PUT request containing JSON as a body.
   * The `body` parameter can be any object that is convertable to JSON using the `JSON.stringify()` function.
   *
   * You may provide a type for the `T` generic to resolve to a `TypedJsonResponse`
   * (otherwise it resolves to a standard `Response`). The response is not decoded for you.
   * You should still use `response.json()` to retrieve an object (the object will have the provided type).
   * You should not use it if you do not expect a JSON response.
   *
   * ```
   * const response = putJson<User>(`${baseUrl}/users/${username}`, {
   *   email: 'john.doe@example.com'
   * });
   * const user = response.json();
   * // `user` constant has the type `User`
   * ```
   */
  putJson<T = undefined>(
    input: RequestInfo,
    body: unknown,
    init?: RequestInit,
  ): ApiFetchReturnType<T>;
}

export type AppApiPartFunction = (...args: unknown[]) => Promise<Response>;

export type AppApiPart = (
  props: AppApiPartProps,
) => Record<string, AppApiPartFunction>;

/* Source: https://stackoverflow.com/questions/50374908/transform-union-type-to-intersection-type */
type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I,
) => void
  ? I
  : never;

export function createAppApiClient<T extends AppApiPart[]>(
  config: LegolandApiClientConfig,
  parts: T,
): UnionToIntersection<ReturnType<T[number]>> {
  function apiFetch<T = undefined>(input: RequestInfo, init?: RequestInit) {
    return fetch(input, {
      credentials: 'include',
      ...init,
    }) as T extends undefined
      ? Promise<Response>
      : Promise<TypedJsonResponse<T>>;
  }

  function del<T>(input: RequestInfo, init?: RequestInit) {
    return apiFetch<T>(input, { method: 'DELETE', ...init });
  }

  const get = apiFetch;

  function patch<T>(input: RequestInfo, init?: RequestInit) {
    return apiFetch<T>(input, {
      ...init,
      method: 'POST',
    });
  }

  function patchJson<T>(input: RequestInfo, body: unknown, init?: RequestInit) {
    return apiFetch<T>(input, {
      ...init,
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json', ...init?.headers },
      method: 'PATCH',
    });
  }

  function post<T>(input: RequestInfo, init?: RequestInit) {
    return apiFetch<T>(input, {
      ...init,
      method: 'POST',
    });
  }

  function postJson<T>(input: RequestInfo, body: unknown, init?: RequestInit) {
    return apiFetch<T>(input, {
      ...init,
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json', ...init?.headers },
      method: 'POST',
    });
  }

  function put<T>(input: RequestInfo, init?: RequestInit) {
    return apiFetch<T>(input, {
      ...init,
      method: 'PUT',
    });
  }

  function putJson<T>(input: RequestInfo, body: unknown, init?: RequestInit) {
    return apiFetch<T>(input, {
      ...init,
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json', ...init?.headers },
      method: 'PUT',
    });
  }

  return Object.assign(
    {},
    ...parts.map((part) =>
      part({
        ...config,
        apiFetch,
        del,
        get,
        patch,
        patchJson,
        post,
        postJson,
        put,
        putJson,
      }),
    ),
  );
}

export type AppApiClient = UnionToIntersection<
  ReturnType<(typeof apiParts)[number]>
>;
