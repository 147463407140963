import { CollectionDto } from 'app/api/app-api/apiTypes';
import {
  AccessHistoryItem,
  NewUser,
  UserDto,
  UserUpdateDto,
} from 'app/types/User';
import { AccessType } from 'legoland-sdk/dist/experimental';
import queryString from 'query-string';
import { AppApiPartProps } from '../../../api/app-api/createAppApiClient';

export function userManagementApi({
  baseUrl,
  del,
  get,
  patchJson,
  postJson,
  putJson,
  patch,
}: AppApiPartProps) {
  return {
    async addAccess({
      expiryDate,
      productId,
      username,
      type,
    }: {
      expiryDate: string;
      productId: string;
      username: string;
      type: AccessType;
    }) {
      return await putJson(
        `${baseUrl}/users/${encodeURIComponent(username)}/access/${productId}`,
        { expiryDate, type },
      );
    },

    addUser({ user }: { user: NewUser }) {
      return postJson(`${baseUrl}/users`, user);
    },

    async changeEmail({
      currentEmail,
      newEmail,
    }: {
      currentEmail: string;
      newEmail: string;
    }) {
      return await patchJson<UserDto>(
        `${baseUrl}/users/${encodeURIComponent(currentEmail)}`,
        { email: newEmail },
      );
    },

    async checkApiKey({
      productId,
      apiKey,
    }: {
      productId: string;
      apiKey: string;
    }) {
      return await get<{ legitimate: boolean }>(
        `${baseUrl}/products/check-api-key?apikey=${apiKey}&product=${productId}`,
      );
    },

    async getAccessHistory({ username }: { username: string }) {
      return await get<Record<string, AccessHistoryItem[]>>(
        `${baseUrl}/access-history/${encodeURIComponent(username)}`,
      );
    },

    async getAccessHistoryByProduct({
      productId,
      username,
    }: {
      productId: string;
      username: string;
    }) {
      return await get<AccessHistoryItem[]>(
        `${baseUrl}/access-history/${encodeURIComponent(
          username,
        )}/${productId}`,
      );
    },

    async getActivationLink({ username }: { username: string }) {
      return await get<{ activationLink: string }>(
        `${baseUrl}/users/${encodeURIComponent(username)}/activation-link`,
      );
    },

    async getApigeeDeveloper({ username }: { username: string }) {
      return await get<{ mail: string }>(
        `${baseUrl}/user-developers/${encodeURIComponent(username)}`,
      );
    },

    async getUserList({
      organizationId,
      username,
      size,
    }: {
      organizationId?: number;
      username?: string;
      size?: number;
    }) {
      const query = { email: username, organizationId, size };
      const url = new URL(`${baseUrl}/users`);
      url.search = queryString.stringify(query);
      return await get<CollectionDto<UserDto>>(url.toString());
    },

    // async getUserList({
    //   organizationId,
    //   username,
    //   size,
    //   filters,
    //   page,
    // }: {
    //   organizationId?: number;
    //   username?: string;
    //   size?: number;
    //   filters: {
    //     searchValues: string;
    //   };
    //   page: Page;
    // }) {
    //   const filterQuery = {
    //     ...filters,
    //     page: page?.index,
    //     size: page?.size,
    //     organizationId,
    //     email: username,
    //   };
    //   const url = new URL(`${baseUrl}/users`);
    //   url.search = queryString.stringify(filterQuery);
    //   return await get<CollectionDto<UserDto>>(url.toString());
    // },

    async getUsersListReports(query) {
      const { filters, sortBy, page } = query;
      const filterQuery = {
        ...filters,
        page: page?.index,
        size: page?.size,
        sort:
          sortBy && sortBy.length > 0
            ? `${sortBy[0].id},${sortBy[0].desc ? 'DESC' : 'ASC'}`
            : undefined,
      };
      const url = new URL(`${baseUrl}/users`);
      url.search = queryString.stringify(filterQuery);
      return get<CollectionDto<UserDto>>(url.toString());
    },

    async removeAccess({
      productId,
      username,
    }: {
      productId: string;
      username: string;
    }) {
      return await del(
        `${baseUrl}/users/${encodeURIComponent(username)}/access/${productId}`,
      );
    },

    async updateUser({ update }: { update: UserUpdateDto }) {
      return await patchJson<UserDto>(
        `${baseUrl}/users/${encodeURIComponent(update.email)}`,
        update,
      );
    },

    async updateApigeeDeveloper({
      mail,
      username,
    }: {
      mail: string;
      username: string;
    }) {
      return await putJson(
        `${baseUrl}/user-developers/${encodeURIComponent(username)}`,
        { mail },
      );
    },

    async rotateApigeeDeveloperKey({ username }: { username: string }) {
      return await patch(
        `${baseUrl}/users/${encodeURIComponent(username)}/api-key-rotations `,
      );
    },
  };
}
