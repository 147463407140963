import { mergeAsyncStates } from 'app/hooks/useAsync';
/**
 * For easier migration
 */
export function queryResultToAsyncState(queryResult, value) {
  return {
    error: queryResult.error,
    status: queryResult.isLoading ? 'pending' : queryResult.isError ? 'rejected' : 'resolved',
    value
  };
}

/**
 * @deprecated
 */
export default function AsyncSuspense(_ref) {
  let {
    children = null,
    pending = null,
    rejected = null,
    state
  } = _ref;
  const targetState = Array.isArray(state) ? mergeAsyncStates(...state) : state;
  const {
    error,
    status
  } = targetState;
  switch (status) {
    case 'ready':
      return null;
    case 'pending':
      return pending && (typeof pending === 'function' ? pending() : pending);
    case 'rejected':
      if (rejected) return rejected && (typeof rejected === 'function' ? rejected(error) : rejected);
      throw error;
    case 'resolved':
      return children && (typeof children === 'function' ? children() : children);
    default:
      throw new Error(`Invalid async status "${status}"`);
  }
}