import {
  FeatureCollection,
  Geometry,
  MultiPolygon,
  Polygon,
  Position,
} from '@turf/helpers';

/**
 * Shifts duplicated coordinates slightly to prevent self-intersections.
 *
 * `unkinkPolygon` does not support polygons, which contain duplicated
 * coordinates other than the first and last. This function shifts duplicated
 * coordinates slightly to prevent self-intersections.
 *
 * @param polygon The input polygon to make unkinkable. Can be a JSON string.
 * @param shiftMagnitude The magnitude of the shift to apply to duplicated
 *   coordinates. Defaults to 1e-10.
 * @returns A new polygon with adjusted coordinates to prevent
 *   self-intersections or the original polygon if no adjustments were made.
 */
export function makeUnkinkable(
  polygon:
    | string
    | Polygon
    | MultiPolygon
    | FeatureCollection<Polygon | MultiPolygon>,
  shiftMagnitude: number = 1e-10,
) {
  // Deep copy the polygon to avoid mutating the input
  const newPolygon:
    | Polygon
    | MultiPolygon
    | FeatureCollection<Polygon | MultiPolygon> = JSON.parse(
    typeof polygon === 'string' ? polygon : JSON.stringify(polygon),
  );

  let isModified = false;

  switch (newPolygon.type) {
    case 'Polygon':
    case 'MultiPolygon':
      isModified = shiftGeometryCoords(newPolygon, shiftMagnitude);
      break;
    case 'FeatureCollection':
      isModified = shiftFeatureCollectionCoords(newPolygon, shiftMagnitude);
      break;
    default:
  }

  if (!isModified && typeof polygon !== 'string') {
    // If the polygon was not modified, return the original reference.
    return polygon;
  }

  return newPolygon;
}

function shiftFeatureCollectionCoords(
  featureCollection: FeatureCollection,
  shiftMagnitude: number,
) {
  let modified = false;

  for (const feature of featureCollection.features) {
    switch (feature.geometry.type) {
      case 'Polygon':
      case 'MultiPolygon': {
        const result = shiftGeometryCoords(feature.geometry, shiftMagnitude);
        modified = modified || result;
        break;
      }
      default:
    }
  }

  return modified;
}

function shiftGeometryCoords(geometry: Geometry, shiftMagnitude: number) {
  let modified = false;

  for (const coords of geometry.coordinates) {
    switch (geometry.type) {
      case 'Polygon': {
        const result = shiftCoords(coords as Position[], shiftMagnitude);
        modified = modified || result;
        break;
      }
      case 'MultiPolygon':
        for (const ring of coords as Position[][]) {
          const result = shiftCoords(ring, shiftMagnitude);
          modified = modified || result;
        }
        break;
      default:
    }
  }

  return modified;
}

function shiftCoords(coords: number[][], shiftMagnitude: number) {
  let modified = false;
  const seen = new Map<string, boolean>();

  // Skip the first and last coordinates, as they are allowed to be duplicated
  for (let i = 1; i < coords.length - 1; i++) {
    const key = coords[i].join(',');
    if (seen.has(key)) {
      // If duplicate, adjust by the specified shift magnitude
      coords[i][0] += shiftMagnitude;
      coords[i][1] += shiftMagnitude;
      // Store as seen in case of further duplicates of the adjusted coordinates
      seen.set(coords[i].join(','), true);
      modified = true;
    } else {
      seen.set(key, true);
    }
  }

  return modified;
}
