import { parse } from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

export function useRedirectQsParam() {
  const location = useLocation();
  const history = useHistory();
  const query = parse(location.search);

  let redirectUrl: URL;

  try {
    redirectUrl =
      typeof query.redirect === 'string' ? new URL(query.redirect) : undefined;
  } catch {
    // invalid URL, do nothing
  }

  const redirectString =
    redirectUrl?.protocol.match(/^https?:$/) &&
    redirectUrl.hostname.match(/^(.+\.)?tomtom.com$/)
      ? (query.redirect as string)
      : undefined;

  return {
    url: redirectString,
    redirect() {
      if (
        redirectUrl.protocol === window.location.protocol &&
        redirectUrl.hostname === window.location.hostname &&
        redirectUrl.port === window.location.port
      ) {
        history.replace(redirectUrl.pathname + redirectUrl.search);
      } else {
        window.location.replace(redirectString);
      }
    },
  };
}
