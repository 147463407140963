import { format, parse } from 'date-fns';
import {
  isMeDetailsFallback,
  useMeDetailsQuery,
} from 'legoland-sdk/dist/experimental';

const defaultDateFormat = 'dd/MM/yyyy';
const defaultTimeFormat = 'HH:mm';

export function useUserDateFormat({
  withTime = false,
}: { withTime?: boolean } = {}) {
  const { data } = useMeDetailsQuery();
  let dateFormat = defaultDateFormat;
  let timeFormat = defaultTimeFormat;
  if (
    data?.meDetails &&
    !isMeDetailsFallback(data.meDetails) &&
    data.meDetails.settings
  ) {
    dateFormat =
      data.meDetails.settings.dateFormat === 'MMDDYYYY'
        ? 'MM/dd/yyyy'
        : 'dd/MM/yyyy';
    timeFormat =
      data.meDetails.settings.clockFormat === '12' ? 'hh:mm aaaa' : 'HH:mm';
  }
  return dateFormat + (withTime ? ` ${timeFormat}` : '');
}

export function useFormatUserDate({
  withTime = false,
}: { withTime?: boolean } = {}) {
  const userDateFormat = useUserDateFormat({ withTime });

  return function formatDate(date: string | Date) {
    if (typeof date === 'string') {
      date = parse(date, 'yyyy-MM-dd', new Date());
    }
    return format(date, userDateFormat);
  };
}
