import { useAppConfig } from 'core/App/AppConfigContext';
import { useMeQuery } from 'legoland-sdk/dist/experimental';
import { useEffect, useState } from 'react';

async function stringToHash(string: string) {
  const buffer = await crypto.subtle.digest(
    'SHA-256',
    new TextEncoder().encode(string),
  );
  const array = Array.from(new Uint8Array(buffer));
  return array.map((b) => b.toString(16).padStart(2, '0')).join('');
}

export function useFeatureToggle(name: string) {
  const feature = useAppConfig().features[name];
  const meQuery = useMeQuery();

  const [isLoading, setIsLoading] = useState(true);
  const [meHash, setMeHash] = useState<string | undefined>();

  useEffect(
    function generateMeHash() {
      if (meQuery.isLoading) {
        return;
      }
      if (
        meQuery.data?.me &&
        feature &&
        !feature.enabled &&
        feature.enabledForUsers
      ) {
        setIsLoading(!meHash);
        stringToHash(meQuery.data.me.email).then((hash) => {
          setMeHash(hash);
          setIsLoading(false);
        });
      } else {
        setMeHash(undefined);
        setIsLoading(false);
      }
    },
    [feature, meQuery],
  );

  return {
    isLoading,
    enabled: isLoading
      ? null
      : feature.enabled || feature.enabledForUsers?.includes(meHash),
  };
}
