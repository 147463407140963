import { useEffect, useState } from 'react';

const useInputValidation = (input, validator) => {
  const [valid, setValid] = useState(true);

  useEffect(() => {
    const isValid = input.value.length === 0 ? true : validator(input.value);
    if (isValid !== valid) {
      setValid(isValid);
    }
  }, [input.value]);

  return valid;
};

export default useInputValidation;
