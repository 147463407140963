import moment from 'moment';
import { useMemo } from 'react';
import { number, object, string } from 'yup';
import { ContractType } from "../contractTypes";
const requiredOrganizationIdSchema = number().nullable().required('Organization is required');
export function useContractValidationSchema() {
  return useMemo(() => object({
    contractId: string().nullable().max(255, 'Contract ID cannot be longer than 255 characters'),
    name: string().nullable().max(255, 'Name cannot be longer than 255 characters'),
    organization: object({
      id: number().nullable()
    }).nullable().when('type', {
      is: type => type === ContractType.Evaluation || ContractType.Internal,
      then: schema => schema.shape({
        id: requiredOrganizationIdSchema
      })
    }).when('type', {
      is: type => type === ContractType.License || type === ContractType.Distribution,
      then: schema => schema.shape({
        id: requiredOrganizationIdSchema,
        sapId: string().required('SAP ID is required')
      })
    }),
    startDate: string().nullable().required('Start date is required'),
    endDate: string().nullable().required('End date is required').when('startDate', (startDate, schema) => {
      return schema.test({
        test: endDate => moment(startDate).isBefore(moment(endDate)),
        message: 'End date is invalid'
      });
    }),
    type: string().nullable().required('Contract type is required')
  }), []);
}