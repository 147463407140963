import {
  createApiQueryHook,
  useApiMutation,
} from 'app/api/app-api/apiHooksHelpers';
import { useQueryClient } from 'react-query';

export const useProductsDataQuery = createApiQueryHook(
  () => ['dashboard', 'products'],
  'getProductsData',
);

export const useUserDataQuery = createApiQueryHook(
  () => ['dashboard', 'user'],
  'getUserData',
);

export function usePublishSellerFormMutation() {
  const queryClient = useQueryClient();
  return useApiMutation('publishSellerForm', {
    mutationOptions: {
      onSettled() {
        queryClient.invalidateQueries(['dashboard', 'user']);
      },
    },
  });
}

export function usePublishSupportFormMutation() {
  const queryClient = useQueryClient();
  return useApiMutation('publishSupportForm', {
    mutationOptions: {
      onSettled() {
        queryClient.invalidateQueries(['dashboard', 'user']);
      },
    },
  });
}
