import { CustomerType, UserDto, UserNote, UserUpdateDto } from 'app/types/User';
import { UserStatus } from 'app/types/UserStatus';
import { AccessType, UserRole } from 'legoland-sdk/dist/experimental';
import defaultApiClient from '../modules/core/apiClient';
import Response from './Response';

export interface UsersQueryFilters {
  roles?: UserRole[];
  products?: string[];
  statuses?: UserStatus[];
  customerTypes?: CustomerType[];
  productAccessTypes?: AccessType[];
  searchValues?: string;
}

const getUserNotes = async (email: string): Promise<UserNote[]> => {
  const response = await Response.fromPromise(
    defaultApiClient
      .request(`/users/${email}/notes`, {
        method: 'GET',
      })
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        }
        return Promise.reject();
      }),
  );

  return response;
};

const createUserNotes = async (email: string, body: any): Promise<UserNote> => {
  const response = await defaultApiClient
    .request(`/users/${email}/notes`, {
      method: 'POST',
      body: JSON.stringify(body),
    })
    .then((response) => {
      if (response.status === 201) {
        return response.json();
      }
    });
  return response;
};

const deleteUserNotes = async (email: string, id: number): Promise<any> => {
  const response = Response.fromPromise(
    defaultApiClient
      .request(`/users/${email}/notes/${id}`, {
        method: 'DELETE',
      })
      .then((response) => {
        if (response.status === 204) {
          return Promise.resolve();
        }
        return Promise.reject();
      }),
  );
  return response;
};

const updateUserNotes = async (email: string, body: any): Promise<UserNote> => {
  const response = defaultApiClient
    .request(`/users/${email}/notes`, {
      method: 'PUT',
      body: JSON.stringify(body),
    })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      }
      return Promise.reject();
    });

  return response;
};

const create = async (user: UserUpdateDto): Promise<Response<UserDto>> => {
  const response = await Response.fromPromise(
    defaultApiClient
      .request(`/users`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      })
      .then((x) => x.json()),
  );
  return response;
};

const changeEmail = async (currentEmail: string, newEmail: string) => {
  const response = await Response.fromPromise(
    defaultApiClient
      .request(`/users/${encodeURIComponent(currentEmail)}`, {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: newEmail }),
      })
      .then((x) => x.json()),
  );
  return response;
};

const isAvailable = async (email: string): Promise<boolean> => {
  const response = await defaultApiClient.request(
    `/users?exists=${encodeURIComponent(email)}`,
  );
  const json = await response.json();

  return !json;
};

const resendEmail = async (id: string): Promise<Response<boolean>> => {
  const response = await Response.fromPromise(
    defaultApiClient
      .request(`/users/${encodeURIComponent(id)}/emails/activations`, {
        method: 'POST',
      })
      .then((x) => x.json()),
  );

  return response;
};

export interface UserList {
  content: UserDto[];
  number: number;
  size: number;
  totalPages: number;
  totalElements: number;
}

const me = async (): Promise<Response<UserList>> => {
  const response = await Response.fromPromise(
    defaultApiClient
      .request('/users/self', {
        method: 'GET',
      })
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        }
        return Promise.reject();
      }),
  );

  return response;
};

const updateSettings = async (settings: any): Promise<Response<any>> => {
  const response = Response.fromPromise(
    defaultApiClient
      .request('/users/self/settings', {
        method: 'PATCH',
        body: JSON.stringify(settings),
      })
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve();
        }
        return Promise.reject();
      }),
  );
  return response;
};

const updateEmailNotifications = async (
  notifications: any,
): Promise<Response<any>> => {
  const response = Response.fromPromise(
    defaultApiClient
      .request('/users/self/email-notifications', {
        method: 'PATCH',
        body: JSON.stringify(notifications),
      })
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve();
        }
        return Promise.reject();
      }),
  );
  return response;
};

const setPassword = async (
  oldPassword: string,
  newPassword: string,
  newPasswordConfirmation: string,
): Promise<Response<any>> => {
  const response = Response.fromPromise(
    defaultApiClient
      .request('/users/self/passwords', {
        method: 'PATCH',
        body: JSON.stringify({
          oldPassword,
          newPassword,
          newPasswordConfirmation,
        }),
      })
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve();
        }
        return Promise.reject();
      }),
  );
  return response;
};

const tryForFree = async (productId: string): Promise<Response<any>> => {
  const response = Response.fromPromise(
    defaultApiClient
      .request(`/users/self/products/${productId}`, {
        method: 'PUT',
      })
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve();
        }
        return Promise.reject();
      }),
  );
  return response;
};

const UserApi = {
  getUserNotes,
  createUserNotes,
  deleteUserNotes,
  updateUserNotes,
  changeEmail,
  create,
  isAvailable,
  resendEmail,
  me,
  updateSettings,
  updateEmailNotifications,
  setPassword,
  tryForFree,
};

export default UserApi;
